import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  url = environment.serverUrl  + '/courses/search/courses';

  constructor(private http: HttpClient) { }

  searchDetails(data){
    const keyword = {
      'keyword' : data,
    }
    return this.http.get<any>(this.url, {params:keyword}  )
    .pipe(
      map((result)=>{
        return{
          courseData : result.data.map((data,index)=>{
            return{
              courseId : data.courseId,
              videoUrl : data.thumbnailImage,
              backgroundImage: data.backgroundImage,
              courseIntroVideo: data.courseIntroVideo,
              courseName : data.courseName,
              courseType: data.courseType,
              price : data.price,
              longDesc : data.description,
              thumbnailDesc: data.newCourseDesc,
              strikedPrice : data.strikedPrice,
              description : data.courseDesc,
              duration : data.duration,
              totalDuration: data.totalDuration,
              totalRating : data.totalRating,
              averageRating : data.averageRating,
              categoryDesc  : data.categoryDesc,
              categoryName : data.categoryName,
              instructor : data.Instructors.map((instdetail,index)=>{
                return{
                  firstName : instdetail.firstName,
                  lastName : instdetail.lastName,
                  designation : instdetail.designation,
                  instructorDescription : instdetail.description,
                  courseInsructor : instdetail.CourseInstructor.courseId,

                }
                
              }),
              courseLearning : data.courseLearnings.map((leaningDetail,index)=>{
                return{
                  learningText : leaningDetail.learningText,
                }
              }),
              coursePreReqs : data.coursePreReqs.map(PreReq=>{
                return{
                  preReqText : PreReq.preReqText
                }
              }),
              acrreditations : data.acrreditations.map(acrreditations=>{
                return{
                  accreditationsName : acrreditations.accreditationsName,
                  accreditationsLogo : acrreditations.accreditationsLogo,
                }
              }),

              CourseModules : data.CourseModules.map(CourseModules=>{
                return{
                  courseModuleId : CourseModules.courseModuleId,
                  courseId : CourseModules.courseId,
                  moduleName : CourseModules.moduleName,
                  CourseLessons : CourseModules.CourseLessons.map(CourseSubModules=>{
                    return{
                      CourseLessonId : CourseSubModules.CourseLessonId,
                      lessonName : CourseSubModules.lessonName,
                      lessonUrl : CourseSubModules.standardVideo,
                      duration : CourseSubModules.duration
                    }
                  })
                }
              }),
              
              userRatings : data.userRatings.map((userRating)=>{
                return{
                  rating : userRating.rating,
                  reviews : userRating.reviewComments,
                  firstName : userRating.User.firstName,
                  lastName : userRating.User.lastName,

                }
              }),
              courseCertifications : data.courseCertifications.map(courseCertifications=>{
                return{
                  courseId : courseCertifications.courseId,
                  certificationHead : courseCertifications.certificationHead,
                }
              }),
              courseAreas : data.courseAreas.map(courseAreas=>{
                return{
                  courseAreaName : courseAreas.courseAreaName,
                }
              })
            }

          })
        }
      })
    )
  }
}
