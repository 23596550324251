import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.scss']
})
export class EmailLoginComponent implements OnInit {
  public emailUserForm: any;
  public hide = true;
  public hideConfirm = true;
  public seconds:any = 60;
  public timer;
  public displayText = 'Try in #1 seconds';
  public setTimer;

  constructor(public authservice: AuthService,  private formBuilder: FormBuilder, public router: Router, private service: UserService, public snackBar: MatSnackBar) { 
    this.authservice.deleteToken()
    this.emailUserForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],          
    });
  }

  onSubmit(){
    const emailUsers = {
      userEmail: this.emailUserForm.get('email').value,
      userPassword: this.emailUserForm.get('password').value,
      // usermode : "Email"
    }
    if(this.emailUserForm.valid){
      this.authservice.emailSignIn(emailUsers)
      .then(result=>{
        // console.log(result)
        if(result.user.emailVerified !== true){
          // this.SendVerificationMail();
          Swal.fire({  
            icon: 'question',    
            title: 'Verify your Email',
            html: '<div style="font-size:14px;">We have sent an email to' + " " + "<b>" + result.user.email + "</b></div>" + ' <div style="margin-top:20px;text-align:justify;font-size:14px;"> You need to verify your email to continue. If you have not received the verification email, please check your "Spam" or "Bulk Email" folder. You can also click the resend button below to have another email sent to you </div>',
            confirmButtonText: `Resend verfication e-mail`,
            showCloseButton: true,
            heightAuto: false,
            allowOutsideClick: false,
            footer:  this.displayText.replace(/#1/, this.seconds), 
            customClass: {
              icon: 'swal-icon',
            },
          }).then(result=>{
            if(result.isConfirmed){
               this.authservice.SendVerificationMail();
              Swal.fire({
                icon: 'success',
                text: "We have re-sent the account verification link to your email address. Please click on the click given in the email to verify your account.",
                heightAuto: false,
                allowOutsideClick: false,
              })
            }
          })
          let x = document.getElementsByClassName('swal2-footer');
          let y = document.getElementsByClassName('swal2-confirm');
          y[0].setAttribute('disabled', 'disabled');
          this.setTimer = setInterval(()=>{
            this.seconds--;
            if(this.seconds <= 0){
              y[0].removeAttribute('disabled');
              x[0].innerHTML = " ";
              clearInterval(this.setTimer);
            
            }else{
              x[0].innerHTML = this.displayText.replace(/#1/, this.seconds);
            }
          
          },600)
        }
        else{
          this.service.getLoginDetails(emailUsers).subscribe(result =>{
                this.service.setItem(result.token)
                this.service.userDetails = result;
                this.router.navigate([''])
                
              })
            // this.router.navigate(['layout/dashboard']);
          
        }
        // this.SetUserData(result.user)
      }).catch(error =>{
        Swal.fire({  
          icon : 'error',      
          text: error.message,
          showConfirmButton: true,
          heightAuto: false,
          allowOutsideClick: false,
        })
      })
      // .then(data=>{
      //   console.log(data)
      //   this.service.getLoginDetails(emailUsers).subscribe(result =>{
      //     this.service.setItem(result.token)
      //     this.service.userDetails = result;
      //     console.log(result)
      //     // this.router.navigate(['layout/dashboard'])
          
      //   })
      // }).catch(err =>{
      //   console.log(err)
      //   Swal.fire({        
        
      //     icon: 'error',
      //     text: 'Incorrect Email or Password !',
      //     footer:'<div>Please Check Your Email and Password</div>',
      //     showConfirmButton: true,
      //     timer: 5000,
      //     heightAuto: false,
      //   })
      // })
      
    }else{
      Swal.fire({        
        
        icon: 'error',
        text: 'Incorrect Email or Password !',
        footer:'<div>Please Check Your Email or Password</div>',
        showConfirmButton: true,
        timer: 5000,
        heightAuto: false,
        allowOutsideClick: false,
      })
    }
    
  }

  keytab(event){
    let element = event.srcElement.nextElementSibling; // get the sibling element

    if(element == null)  // check if its null
        return;
    else
        element.focus();   // focus if not null
}
  

  ngOnInit(): void {
  }

}
