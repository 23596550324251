import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  styleUrls: ['./skeleton-loader.component.scss']
})
export class SkeletonLoaderComponent implements OnInit {

  @HostBinding('class.skeleton-text-animated') isAnimated = false;

  @Input('animated')
  set animated(value: boolean) {
    this.isAnimated = coerceBooleanProperty(value);
  }

  constructor() { }

  ngOnInit(): void {
  }

}
