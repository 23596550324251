<footer class="footer border-top-small border-light-divider">
    <!-- <div class="container pt-4">
        <div class="row text-center text-md-left">
            <div class="col-lg-3 col-md-3">
                <a class="logo-footer" href="#">Course Assessment</a>
                <p class="mt-4">Course Assessment provides courses to aspiring aviation students and professionals who wish to cross skill themselves.</p>
                <ul class="list-unstyled social-icon social mb-0 mt-4">
                    <li class="list-inline-item"><a href="https://www.facebook.com/Smart-GC-Pro-101146525493585" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook fea icon-sm fea-social"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></a></li>
                    <li class="list-inline-item"><a href="https://twitter.com/home" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter fea icon-sm fea-social"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg></a></li>
                    <li class="list-inline-item"><a href="https://www.linkedin.com/in/smart-gc-pro-033805211/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin fea icon-sm fea-social"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg></a></li>
                    <li class="list-inline-item"><a href="https://www.instagram.com/smartgcpro/" target="_blank"> <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin fea icon-sm fea-social"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"/><path d="M16 11.37A4 4 0 1 1 12.63 8A4 4 0 0 1 16 11.37z"/><path d="M17.5 6.5h.01"/></svg></a></li>
                </ul>
            </div>
           

            <div class="col-md-3 col-lg-3 mt-3 mt-md-0 pl-md-5">
                <h4 class="text-light footer-head">Company</h4>
                <ul class="list-unstyled footer-list mt-4">
                    <li><a [routerLink]="'/'" class="text-foot">Home</a></li>
                    <li><a [routerLink]="'/company/about-us'" [routerLinkActive]="'active'" class="text-foot">About us</a></li>
                    <li><a [routerLink]="'/company/contact'" [routerLinkActive]="'active'" class="text-foot">Contact Us</a></li>
                    <li><a href="http://blog.smartgcpro.in/" class="text-foot">Blog</a></li>
                    <li><a [routerLink]="'/company/faq'" [routerLinkActive]="'active'" class="text-foot">FAQ</a></li>
                </ul>
            </div>
            
            <div class="col-md-3 col-lg-3 mt-4 mt-md-0">
                <h4 class="text-light footer-head">Useful Links</h4>
                <ul class="list-unstyled footer-list mt-4">
                    <li><a [routerLink]="'/company/privacy-policy'" [routerLinkActive]="'active'" class="text-foot">Privacy Policy</a></li>
                    <li><a [routerLink]="'/company/terms'" [routerLinkActive]="'active'" class="text-foot">Terms & Conditions</a></li>
                    <li><a [routerLink]="'/company/disclaimer'" [routerLinkActive]="'active'" class="text-foot">Disclaimer</a></li>
                    
                </ul>
            </div>
            <div class="col-md-3 col-lg-3 mt-4 mt-md-0">
                <h4 class="text-light footer-head">Careers</h4>
                <p>View our career Opportunities</p>
                <button mat-flat-button color="accent" class="w-100" [routerLink]="'/company/careers'" [routerLinkActive]="'active'">Careers</button>
            </div>
        </div>

    </div> -->
    <!--end container-->
    <div class="border-top-medium border-accent p-3 mt-3">
        <p class="mb-0 text-accent-dark">All Rights Reserved. © Copyright 2024. Course Assessment.</p>
    </div>
</footer>