import { Component, OnInit, HostBinding, Output, EventEmitter, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { UserService } from 'src/app/services/user.service';
import { MyCartService } from 'src/app/services/my-cart.service';
import { SearchService } from 'src/app/services/search.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SearchFilterService } from 'src/app/services/search-filter.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @HostBinding('class.sticky-top') fixedTop = true;

  @Output() toggleClick = new EventEmitter<boolean>();
  @Output() searchChange = new EventEmitter<string>();

  cartCountSubscription: Subscription;
  userDetailsSubscription: Subscription;

  isLoggedin = false;
  values: string;
  cartBadge = 0;
  userName: any;
  firstLetter: any;
  userDetails: any;
  isShowDiv = false;
  public categoryDetails: any = [];

  constructor(public authservice: AuthService, public service: CommonServiceService, public userservice: UserService,
              public searchService: SearchFilterService, public cartService: MyCartService,
              public searchKey: SearchService, public router: Router) {
    this.values = this.service.searchKeyword;

    this.cartCountSubscription = this.cartService.getCartCountListener().subscribe((cartCount) => {
      this.cartBadge = cartCount;
    });

    this.userDetailsSubscription = this.userservice.getUserDetailsListener().subscribe((userDetails) => {
      if (this.userservice.userDetails !== undefined) {
        this.isLoggedin = true;
        this.userName = this.userservice.userDetails.data;
        this.firstLetter = this.userName.firstName.slice(0, 1) + this.userName.lastName.slice(0, 1);
      } else {
        this.isLoggedin = false;
      }
    });

    this.searchService.getCategoryDetails('').subscribe(category => {
      category.CategoryData.forEach(data => {
        this.categoryDetails.push(data);
      });
    });


    this.userservice.getStoredUserDetails();
    this.cartService.getCartCount();
  }
  ngOnDestroy(): void {
    this.cartCountSubscription.unsubscribe();
    this.userDetailsSubscription.unsubscribe();
  }

  ngOnInit(): void {
  }

  clickCourse(selectedCourse){
    this.service.sharedData = selectedCourse;
    this.router.navigate(['/course-details'], {queryParams: {courseId: selectedCourse.courseId} });
 
  }

  toggleMenuClick() {
    this.toggleClick.emit(true);
  }

  onSearchChange(event) {
    this.searchChange.emit(event.target.value);
  }

  clickWishList(){
    this.router.navigate(['layout/wishlist']);
  }

  clickCart() {
    this.router.navigate(['layout/my-cart']);
  }

  clickTransaction(){
    this.router.navigate(['/layout/transactions']);
  }

  async logout(){
    const service = await this.authservice.logout();
    this.userservice.logout();
  }

  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  over() {
    this.isShowDiv = true;
  }

  out() {
    this.isShowDiv = false;
  }

  overDisplay() {
    this.isShowDiv = false;
  }

}
