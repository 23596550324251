
<mat-sidenav-container>
    <mat-sidenav-content>
        <div class="flex-grow-1 flex-md-grow-0 text-center text-md-left p-4">
            <a class="text-center" href="#">
                <a class="text-center" href="#">
                    <img src="../../assets/images/ca-logo.svg" alt="CA Logo" class="nav-logo">
                </a>
            </a>
        </div>
        <section class="hero-section pt-5">
            <div class="container-fluid">
                <div class="mainDiv">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <h1 class="mat-display-1 text-light text-center learn">
                                Learn. Scale <br />Your Career.</h1>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-center">
                            <form [formGroup]="emailUserForm">
                                <div class="studentBox">
                                    <h1 class="text-light"><b>SIGN UP with e-mail</b></h1>
                                    <div class="form-layout">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <mat-form-field appearance="standard" class="fname">
                                                    <mat-label>First Name</mat-label>
                                                    <input matInput formControlName="firstName" required>
                                                    <mat-error *ngIf="emailUserForm.controls.firstName.touched ">
                                                        Please enter your first name
                                                        </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6">
                                                <mat-form-field appearance="standard" class="lname">
                                                    <mat-label>Last Name</mat-label>
                                                    <input matInput formControlName="lastName" required>
                                                    <mat-error *ngIf="emailUserForm.controls.lastName.touched ">
                                                    Please enter your last name
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row " class="email">
                                            <div class="md-col-12">
                                                <mat-form-field appearance="standard">
                                                    <mat-label>e-mail</mat-label>
                                                    <input matInput formControlName="email" required>
                                                    <mat-error
                                                        *ngIf="emailUserForm.controls.email.invalid && emailUserForm.controls.email.touched ">
                                                        Please enter a valid e-mail address
                                                    </mat-error>
                                                    <mat-error *ngIf="emailUserForm.hasError('required')">
                                                        e-mail is <strong>required</strong>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row" class="password">
                                            <div class="md-col-12">
                                                <mat-form-field appearance="standard">
                                                    <mat-label>Password</mat-label>
                                                    <!-- <small id="materialRegisterFormPasswordHelpBlock" class="form-text text-muted mb-4">
                                                        At least 6 characters and 1 digit
                                                      </small> -->
                                                    <input matInput type="password" formControlName="password" required
                                                        [type]="hide ? 'password' : 'text'"   matTooltipClass="test">
                                                    <button type="button" mat-icon-button matSuffix (click)="hide = !hide" tabindex = "-1"
                                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                    
                                                    <!-- <mat-error *ngIf="emailUserForm.hasError('required', 'password')">
                                                        Please enter your password
                                                    </mat-error> -->
                                                    <mat-error *ngIf="emailUserForm?.controls.password.invalid && emailUserForm?.controls.password.touched">
                                                        Please enter valid password
                                                    </mat-error>
                                                </mat-form-field>
                                                <p class="text-light text-left hint mt-2">Password policy : Password must be atleast 8 character in length.Atleast 1 uppercase (A-Z).Atleast 1 lowercase (a-z).Atleast 1 number (0-9).Atleast 1 special characters (eg : !@#%^&*)."</p>
                                            </div>
                                        </div>
                                        <div class="row " class="confirm-password">
                                            <div class="md-col-12">
                                                <mat-form-field appearance="standard">
                                                    <mat-label>Confirm Password</mat-label>
                                                    <input matInput type="password" formControlName="confirmPassword" required
                                                        [type]="hideConfirm ? 'password' : 'text'">
                                                    <button type="button" mat-icon-button matSuffix (click)="hideConfirm  = !hideConfirm "
                                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide" tabindex = "-2">
                                                        <mat-icon>{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                    <mat-error *ngIf="emailUserForm.controls.confirmPassword.invalid">
                                                        Password do not match
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <re-captcha (resolved)="resolved($event)" formControlName="recaptchaReactive" [siteKey]="siteURL" tabindex = "-3"></re-captcha>
                                        </div>
                                        <button type="submit" mat-button class="signUpBtn mt-3 text-light" (click)="onSubmit()">SIGN UP</button>
                                        <h4 class="pt-4 text-light">By Signing up you agree to our <span class="policies text-light" [routerLink]="'/company/terms'" [routerLinkActive]="'active'">Terms & Conditions</span> and <span class="policies text-light" [routerLink]="'/company/privacy-policy'" [routerLinkActive]="'active'"> Privacy Policies</span></h4>
                                        <hr class="lineBreak">
                                        <h3 class="alreadyMember text-light">Already a member? <span class="text-white" routerLink="/email-login" routerLinkActive="active"><u>Signin</u></span></h3>
    
                                    </div>
    
                                </div>
                            </form>
                              
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </mat-sidenav-content>
</mat-sidenav-container>