import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/services/user.service';
import { CourseVideosComponent } from 'src/app/course/course-videos/course-videos.component';

@Component({
  selector: 'app-feedback-rating',
  templateUrl: './feedback-rating.component.html',
  styleUrls: ['./feedback-rating.component.scss']
})
export class FeedbackRatingComponent implements OnInit {

  public feedbackForm: FormGroup;
  public currentRate: any = ''
  public maxStars = 5
  public stars: number[] = [1, 2, 3, 4, 5];
  public selectedValue;
  public userDetails;
  public feedback: boolean = false;
  public isEdit: boolean = false;
  public ratingId;
  public modified: boolean = false;
  public modifyRating;
  public ratingDetails;

  constructor(public dialogRef: MatDialogRef<CourseVideosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private formBuilder: FormBuilder, public userservice: UserService) {
    this.userDetails = this.userservice.userDetails.data;

    if (data.userRating.length > 0) {
      this.feedbackForm = this.formBuilder.group({
        message: [data.userRating[0].reviewComments]
      });
      this.selectedValue = data.userRating[0].rating
      this.ratingId = data.userRating[0].userRatingId;
      this.isEdit = true
    } else {
      this.feedbackForm = this.formBuilder.group({
        message: ['']

      });
      this.selectedValue = '';
      this.isEdit = false

    }
  }

  countStar(star) {
    this.selectedValue = star;
}
  

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  feedbackDone(){
    this.dialogRef.close({data: this.ratingDetails});
  }

  feedbackModified(){
    this.dialogRef.close({data: this.modifyRating});
  }

  onSubmit() {
    if(this.isEdit){

       this.modifyRating = {
        rating : this.selectedValue,
        reviewComments : this.feedbackForm.get('message').value,
      }

      this.userservice.modifyUserRating(this.ratingId, this.modifyRating).subscribe(data=>{
        if(data.sucess){
          this.modified = true
        }
      })
      

    }else{
      this.ratingDetails = {
        userId: this.userservice.userDetails.data.userId,
        courseId: this.data.courseId,
        rating: this.selectedValue,
        reviewComments: this.feedbackForm.get('message').value
      }
      this.userservice.createUserRating(this.ratingDetails).subscribe(data => {
        if (data.success) {
          this.feedback = true;
        }
      });
    }
  }

}
