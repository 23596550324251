
<div class="container-fluid">
    <div class="row open-dialog">
        <div class="col-sm-12-col-md-12-col-lg-12 col-xs-12">
            <div class="d-flex">
                <button mat-icon-button (click)="onNoClick()"  class="ml-auto">
                    <mat-icon class="text-white">clear</mat-icon>
                </button>
            </div>
            
            <div class="text-content mt-5">
                <p>We would like to hear about your experience. <br/>
                    Please provide your valuable feedback, before you download your certificate.
                </p>
                <p class="mt-5">Thanks for your time !</p>
                <button mat-button class="mt-5 mb-5 submitBtn" (click)=" onSubmit()">Rate us</button>
            </div>
        </div>
    </div>
</div>
