

<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <div class="sidebar">
      <ul class="sidebar-nav pt-3">
        <ng-container *ngIf="!isLoggedin; else LoggedInBlock">
          <li class="mx-3 mb-3">
            <button mat-stroked-button class="border-medium border-light w-100" routerLink="/login" routerLinkActive="active">Sign In</button>
          </li>
          <li class="mx-3 mb-3">
            <button mat-flat-button color="accent" class="w-100" routerLink="/sign-up" routerLinkActive="active">Sign Up</button>
          </li>
        </ng-container>
        <ng-template #LoggedInBlock>
          <li class="p-3 border-bottom-small border-light-divider">
            <div class="profile-segment d-flex align-items-center">
              <div class="profile-img no-image rounded-circle d-flex align-items-center justify-content-center">
                <h1 class="mb-0 ">{{firstLetter}}</h1>
              </div>
              <div class="flex-fill ml-3 overflow-hidden">
                <h3 class="mb-0">{{userName.firstName}}&nbsp;&nbsp;{{userName.lastName}}</h3>
                <p class="d-block mb-0 text-accent text-truncate">{{userName.email}}</p>
              </div>
            </div>
          </li>
          <li class="px-3 pt-3">
            <p class="mat-subheading text-light-disabled text-uppercase mb-0">My Account</p>
          </li>
          <li>
            <button mat-button class="w-100 text-left rounded-0 px-3" routerLink="/layout/dashboard" routerLinkActive="bg-background">
              <mat-icon>local_library</mat-icon>&nbsp;&nbsp;My Learnings
            </button>
          </li>
          <li>
            <button mat-button class="w-100 text-left rounded-0 px-3" routerLink="/layout/wishlist" routerLinkActive="bg-background">
              <mat-icon>favorite_border</mat-icon>&nbsp;&nbsp;Wishlist
            </button>
          </li>
          <li>
            <button mat-button class="w-100 text-left rounded-0 px-3" routerLink="/layout/my-cart" routerLinkActive="bg-background">
              <mat-icon>shopping_cart</mat-icon>&nbsp;&nbsp;My Cart
            </button>
          </li>
          <li>
            <button mat-button class="w-100 text-left rounded-0 px-3" routerLink="/layout/transactions" routerLinkActive="bg-background">
              <mat-icon>account_balance_wallet</mat-icon>&nbsp;&nbsp;Transaction History
            </button>
          </li>
          <li class="pb-2 border-bottom-small border-light-divider">
            <button mat-button class="w-100 text-left rounded-0 px-3" routerLink="/layout/myprofile" routerLinkActive="bg-background">
              <mat-icon>account_circle</mat-icon>&nbsp;&nbsp;My Profile
            </button>
          </li>
          <!-- <li class="pt-4 pl-3 pb-3">
            <div class="dropdown-menu">
              <button mat-icon-button class="pl-4 pt-2 pb-2" (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>&nbsp;&nbsp;Log out
              </button>

            </div>
          </li> -->
        </ng-template>
        <li class="px-3 pt-3">
          <p class="mat-subheading text-light-disabled text-uppercase mb-0">Courses</p>
        </li>
        <li class="nav-item" *ngFor="let category of categoryDetails">
          <a class="nav-link has-submenu text-light-secondary">{{category.categoryName}}</a>
          <ul class="nav-submenu" *ngFor="let course of category.courses" (click)="clickCourse(course)">
            <li class="nav-submenu-item text-light-secondary"><a class="nav-submenu-link">{{course.courseName}}</a></li>
          </ul>
        </li>
        <li class="p-3 border-bottom-small border-light-divider">
          <button mat-stroked-button class="w-100 text-light border-small border-light" (click)="viewAllCourses()">
            EXPLORE ALL COURSES
          </button>
        </li>
        <li class="py-2 border-bottom-small border-light-divider">
          <button mat-button class="w-100 text-left rounded-0 px-3" (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>&nbsp;&nbsp;Log Out
          </button>
        </li>
      </ul>

    </div>

  </mat-sidenav>

  <mat-sidenav-content class="mainContainer d-flex flex-column" style="overflow-x: hidden;">
    <app-header  (toggleClick)="sidenav.toggle()" (searchChange)="onKeyup($event);clickInput()" ></app-header>
    <ng-content></ng-content>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>