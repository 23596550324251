
<mat-sidenav-container>
  <mat-sidenav-content>
    <div class="flex-grow-1 flex-md-grow-0 text-center text-md-left p-4">
      <a class="text-center" href="#">
        <svg xmlns="http://www.w3.org/2000/svg" class="nav-logo" viewBox="0 0 80.84 28.95">
          <image href="../../assets/images/ca-logo.svg" width="80.84" height="28.95" />
        </svg>
      </a>
    </div>
    <section class="hero-section">
      <div class="container-fluid container-lg">
        <div class="mainDiv pt-5">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-sm-12 my-4 order-1 order-md-0 text-center mb-2">
              <div class="studentBox p-2">
                <h1 class="pt-4" style="color: #393185;">SIGN IN to <strong>Course Assessment </strong></h1>
                <button mat-button class="google text-light p-2" (click)="loginWithGoogle()">
                  <!-- <img src="../../assets/images/google-icon.svg" class="img-fluid m-2" /> -->
                 SIGN IN with Google
                </button>
                <button mat-button class="Facebook text-light mt-5 p-2" (click)="loginWithFacebook()">
                  <img src="../../assets/images/facebook.png" class="img-fluid m-2" />
                 SIGN IN with Facebook
                </button>
                <span class="orSection">
                  <hr class="line" flex />&nbsp;&nbsp;
                  <p>or</p>&nbsp;&nbsp;
                  <hr flex class="line" />
                </span>
                <button mat-button class="email text-light p-2" (click)="emailSignin()">
                 SIGN IN with e-mail
                </button>
                <hr class="mt-5 mb-4" />
                <p class="policies" (click)="studentSignUp()">New User? <span>SIGN UP!</span></p>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 my-4 mt-5 order-0 order-md-1 wordings text-right text-sm-center">
              <h1 class="mat-display-1 text-light text-center mt-3 mb-4 learn font-weight-bolder">
                Emerge. Evolve. Excel</h1>
              <h1 class="mat-title text-light text-center mb-4 mt-5 create">
                Create your <b>free</b> account TODAY/NOW!
              </h1>
            </div>
          </div>
        </div>
      </div>
      
    </section>

  </mat-sidenav-content>
</mat-sidenav-container>