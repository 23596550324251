<mat-toolbar color="primary">
  <mat-toolbar-row class="d-flex align-items-center custom-toolbar">
    <button mat-icon-button class="d-md-none mr-2 ml-auto" (click)="toggleMenuClick()">
      <mat-icon>menu</mat-icon>
    </button>
    <div class="flex-grow-1 flex-md-grow-0 text-center">
      <a routerLink="" class="text-center">
        <svg viewBox="0 0 130 60" class="ca-logo" xmlns="http://www.w3.org/2000/svg" width="130" height="60" >
          <image href="../../../../assets/images/ca-logo.svg" x="0" y="0" />
        </svg>
      </a>
    </div>
    <button mat-icon-button class="d-md-none mr-2 ml-auto">
      <mat-icon>search</mat-icon>
    </button>
    <nav class="ml-4 dropdown-menu d-none d-md-block">
      <button class="dropdown-menu-button" mat-button>
        Courses<mat-icon>expand_more</mat-icon>
      </button>
      <div class="dropdown-menu-content left-menu">
        <div class="dropdown-menu-content-animate">
          <div class="dropdown-nav-wrapper mat-elevation-z3">
            <ul class="dropdown-nav">
              <li class="dropdown-nav-item has-submenu" *ngFor="let category of categoryDetails">
                <a class="dropdown-nav-link d-flex">
                  <div class="course-category-nav-link-content mat-body flex-fill">{{category.categoryName}}</div>
                  <div class="next-icon">
                    <svg viewBox="0 0 24 24"><path d="M8.59 7.41L13.17 12l-4.58 4.59L10 18l6-6-6-6-1.41 1.41z"></path></svg>
                  </div>
                </a>
                <div class="dropdown-submenu-content">
                  <ul class="dropdown-submenu-nav">
                    <li class="dropdown-submenu-nav-item" *ngFor="let course of category.courses">
                      <a class="dropdown-submenu-nav-link d-flex" (click)="clickCourse(course)">
                        <div class="dropdown-submenu-nav-link-content mat-body flex-fill">{{course.courseName}}</div>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          
        </div>
        
      </div>
    </nav>
    

    <form class="search-form flex-grow-1 d-none d-md-block">
      <div class="search-form-input-group">
        <label class="mat-caption">Search courses</label>
        <input class="mat-body text-light" id="searchvalue" type="text" placeholder="Search courses"
          (keyup.enter)="onSearchChange($event)">
        <button mat-icon-button>
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </form>
    <ng-container *ngIf="!isLoggedin; else LoggedInBlock">
      <button class="d-none d-md-block" mat-button routerLink="/login" routerLinkActive="active">Sign In</button>
      <button mat-flat-button color="accent" class="ml-2 d-none d-md-block" routerLink="/sign-up"
        routerLinkActive="active">Sign Up</button>
    </ng-container>
    <ng-template #LoggedInBlock>
      <button mat-icon-button class="d-none d-md-block" routerLink="/layout/dashboard" routerLinkActive="active"
        data-toggle="tooltip" data-placement="top" title="My Courses">
        <mat-icon>local_library</mat-icon>
      </button>
      <button mat-icon-button class="d-none d-md-block" (click)="clickWishList()" data-toggle="tooltip"
        data-placement="top" title="Wishlist">
        <mat-icon>favorite_border</mat-icon>
      </button>
      <button mat-icon-button class="d-none d-md-block" data-toggle="tooltip" data-placement="top" title="My Cart">
        <mat-icon [matBadge]="cartBadge" matBadgePosition="after" matBadgeColor="warn" (click)="clickCart()">
          shopping_cart
        </mat-icon>
      </button>

      <nav class="ml-4 dropdown-menu d-none d-md-block">
        <div class="dropdown-menu-button d-flex align-items-center">
          <button class="mat-elevation-z0" mat-mini-fab>
            <span>{{firstLetter}}</span>
          </button>
        </div>
        
        <div class="dropdown-menu-content right-menu">
          <div class="dropdown-menu-content-animate">
            <div class="dropdown-nav-wrapper mat-elevation-z3">
              <ul class="dropdown-nav">
                <li class="p-3 border-bottom-small border-light-divider">
                  <div class="profile-segment d-flex align-items-center">
                    <div class="profile-img no-image rounded-circle d-flex align-items-center justify-content-center">
                      <h1 class="mb-0 ">{{firstLetter}}</h1>
                    </div>
                    <div class="flex-fill ml-3 overflow-hidden">
                      <h3 class="mb-0">{{userName.firstName}}&nbsp;&nbsp;{{userName.lastName}}</h3>
                      <p class="d-block mb-0 mat-caption text-accent text-truncate">{{userName.email}}</p>
                    </div>
                  </div>
                </li>
                <li class="px-3 pt-3">
                  <p class="mat-body text-light-disabled text-uppercase mb-0">My Account</p>
                </li>
                <li>
                  <button mat-button class="w-100 text-left rounded-0 px-3" routerLink="/layout/dashboard" routerLinkActive="bg-background">
                    <mat-icon>local_library</mat-icon>&nbsp;&nbsp;My Learnings
                  </button>
                </li>
                <li>
                  <button mat-button class="w-100 text-left rounded-0 px-3" routerLink="/layout/wishlist" routerLinkActive="bg-background">
                    <mat-icon>favorite_border</mat-icon>&nbsp;&nbsp;Wishlist
                  </button>
                </li>
                <li>
                  <button mat-button class="w-100 text-left rounded-0 px-3" routerLink="/layout/my-cart" routerLinkActive="bg-background">
                    <mat-icon>shopping_cart</mat-icon>&nbsp;&nbsp;My Cart
                  </button>
                </li>
                <li>
                  <button mat-button class="w-100 text-left rounded-0 px-3" routerLink="/layout/transactions" routerLinkActive="bg-background">
                    <mat-icon>account_balance_wallet</mat-icon>&nbsp;&nbsp;Transaction History
                  </button>
                </li>
                <li class="pb-2 border-bottom-small border-light-divider">
                  <button mat-button class="w-100 text-left rounded-0 px-3" routerLink="/layout/myprofile" routerLinkActive="bg-background">
                    <mat-icon>account_circle</mat-icon>&nbsp;&nbsp;My Profile
                  </button>
                </li>
                <li class="py-2">
                  <button mat-button class="w-100 text-left text-accent rounded-0 px-3" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>&nbsp;&nbsp;Log Out
                  </button>
                </li>
              </ul>
            </div>
            
          </div>
          
        </div>
      </nav>
    </ng-template>
  </mat-toolbar-row>
</mat-toolbar>