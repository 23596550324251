<section class="header-section bg-background-dark p-4">
  <div class="container-fluid container-lg">
    <h1 class="mat-title text-light my-2">My Wishlist</h1>
  </div>
</section>

<section class="loader-section py-5 d-flex justify-content-center align-items-center" *ngIf="!isWishlistLoaded">
  <mat-spinner color="accent"></mat-spinner>
</section>

<section class="deatil-section" *ngIf="isWishlistLoaded">
  <div class="container-fluid container-lg">
    <h3 class="mat-title text-light-disabled pt-4 mb-2">{{wishlistLength}} Course(s) in Wishlist</h3>
  </div>
</section>

<section class="wishlist-section" *ngIf="isWishlistLoaded">
  <div class="container-fluid container-lg pt-3 border-top-small border-bottom-small border-light-divider" >
    <div class="text-center my-5" *ngIf="databaseUser?.length === 0">
      <h3 class="text-light-disabled" >No items in Wishlist</h3>
      <button mat-stroked-button color="accent" routerLink="" >Keep Shopping</button>
    </div>
    <div class="course-wishlist-container d-flex flex-column flex-sm-row mb-3" *ngFor="let wishlist of databaseUser; let i = index;" >
      <img class="course-wishlist-img" src="{{wishlist.videoUrl}}" alt="Suresh Dasari Card">
      <div class="course-wishlist-content flex-fill d-flex flex-column">
        <div class="course-wishlist-details flex-fill d-flex flex-column flex-sm-row">
          <div class="flex-fill mr-sm-2">
            <h4 class="course-wishlist-title text-light" (click)="clickCourse(wishlist)">{{wishlist.courseName}}</h4>
            <p class="course-wishlist-desc text-light-disabled">{{wishlist.courseDesc}}</p>
          </div>
          <div class="ml-sm-auto d-flex flex-row flex-sm-column mb-5 text-right">
            <p class="course-wishlist-price">{{wishlist.price | currency:'INR':'symbol-narrow'}}</p>
            <p class="course-wishlist-actual-price text-accent ml-2 ml-sm-0" *ngIf="wishlist.price != wishlist.strikedPrice">{{wishlist.strikedPrice | currency:'INR':'symbol-narrow'}}</p>
          </div>
          
        </div>
        <div class="course-wishlist-actions">
          <a class="text-section-title" (click)="moveToCart(i,wishlist)">Move to Cart</a>
          <a class="text-accent" (click)="removeWishlist(i,wishlist)">Remove</a>
        </div>
      </div>
    </div>
  </div>
</section>