// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  'serverUrl': 'http://localhost:3000',
  'frontEndURL': 'http://localhost:4200/',

   firebase : {
    apiKey: "AIzaSyDMxJ3Rf3V5yDqqLtBYHMN9f7RumFoOdUo",
    authDomain: "si-smartgcpro.firebaseapp.com",
    databaseURL: "https://si-smartgcpro.firebaseio.com",
    projectId: "si-smartgcpro",
    storageBucket: "si-smartgcpro.appspot.com",
    messagingSenderId: "551796475199",
    appId: "1:551796475199:web:9a2edb85f4cfd11c49ff8a"
  },

  captchaUrl : {
    siteUrl : "6LeeReEZAAAAADZGqcEJqrdxjJ7l-uoH7xmh9qvn"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
