import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  url = environment.serverUrl  + '/wishlist';

  constructor(private http: HttpClient,  private router: Router) { }

  addTowishlist(userId, courseId){

    const details = {
      userId : userId,
      courseId : courseId,
    }
    return this.http.post<any>(this.url,details)
  }

  getWishlistCourse(userid){
    return this.http.get<any>(this.url + '/course' +`/${userid}`)
    .pipe(
      map((result)=>{
        // console.log(result)
        return{
          wishlistData : result.data.map((data)=>{
            return{
              userWishlistId : data.userWishlistId,
              courseId : data.courseId,
              courseName : data.courseName,
              courseDesc : data.newCourseDesc,
              videoUrl : data.thumbnailImage,
              backgroundImage: data.backgroundImage,
              courseIntroVideo: data.courseIntroVideo,
              strikedPrice : data.strikedPrice,
              price : data.price,
              instructor : data.Instructors.map(ins=>{
                return{
                  firstName : ins.firstName,
                  lastName : ins.lastName,
                  designation : ins.designation,
                  description : ins.description,

                }
              })
            }
          })
        }
        
      })
    )
  }

  removeWishlist(userId,courseId){

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        userId : userId,
      courseId : courseId,
      },
    };
    
    return this.http.delete<any>(this.url + '/remove' , options)
  }
}
