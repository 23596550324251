import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MyCartService {

  private cartCountUpdated = new Subject<number>();
  private cartCount = 0;

  public sharedData;
  public courseDetails;
  url = environment.serverUrl + '/cart';
  constructor(private http: HttpClient, private router: Router) { }

  getCartCountListener() {
    return this.cartCountUpdated.asObservable();
  }

  updateCartCount(cartCount) {
    this.cartCount = cartCount;
    this.cartCountUpdated.next(this.cartCount);
  }

  increaseCartCount() {
    this.cartCount += 1;
    this.cartCountUpdated.next(this.cartCount);
  }

  decreaseCartCount() {
    if (this.cartCount > 0){
      this.cartCount -= 1;
    }
    this.cartCountUpdated.next(this.cartCount);
  }

  getCartCount() {
    this.cartCountUpdated.next(this.cartCount);
  }

  getCartdetails(userid) {
    return this.http.get<any>(this.url + '/course' + `/${userid}`)
      .pipe(
        map((result) => {
          // console.log(result)
          return {
            cartData: result.data.map((data) => {
              return {
                courseId: data.Course.courseId,
                courseName: data.Course.courseName,
                courseDesc: data.Course.newCourseDesc,
                videoUrl: data.Course.thumbnailImage,
                backgroundImage: data.Course.backgroundImage,
                courseIntroVideo: data.Course.courseIntroVideo,
                strikedPrice: data.Course.strikedPrice,
                discountPercent: (((data.Course.strikedPrice - data.Course.price) / data.Course.strikedPrice) * 100).toFixed(2).slice(0, 2),
                price: data.Course.price,
                instructor: data.Course.Instructors.map(instructor => {
                  return {
                    instructorId: instructor.instructorId,
                    firstName: instructor.firstName,
                    lastName: instructor.lastName,
                  };
                })

              };
            })
          };
        })
      );
  }

  addToCart(userId, courseId) {

    const details = {
      userId,
      courseId,
    };
    return this.http.post<any>(this.url, details);
  }

  removeCart(userId, courseId) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        userId,
        courseId,
      },
    };

    return this.http.delete<any>(this.url + '/remove', options);
  }

}
