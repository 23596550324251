import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CityService {

url =  environment.serverUrl  + '/City';
  constructor(private http: HttpClient,  private router: Router) { }

  getCity(){
    return this.http.get<any>(this.url)
    .pipe(
      map((result)=>{
        return{
          city : result.data.map(data=>{
            return{
              cityId : data.cityId,
              city : data.city,
              state : data.state,
              district : data.district
            }
          })
        }
      })
    )
  }
}
