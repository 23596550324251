import { Component, OnInit } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { UserService } from 'src/app/services/user.service';
import { MyCartService } from 'src/app/services/my-cart.service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { OnlinePaymentService } from 'src/app/services/online-payment.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserProgressService } from 'src/app/services/user-progress.service';

@Component({
  selector: 'app-dashbord-course-details',
  templateUrl: './dashbord-course-details.component.html',
  styleUrls: ['./dashbord-course-details.component.scss']
})


export class DashbordCourseDetailsComponent implements OnInit {

  public userName;
  public cart = false;
  public isShowDiv = false;
  public firstLetter;
  public cartBadge;
  public userDetails;
  public categoryDetails = [];
  public transactionDetails = [];
  public cartDetails;
  public wishlistUser;
  public disabled = false;
  public hide = true;
  public courseId;
  public percentage;
  public values;
  public CoursecompletedTime;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  value = 50;
  sub: Subscription;
  CourseDetails: any = [];
  selectedCouserByUser;
  selectedScreen;


  constructor(public service: CommonServiceService, public userservice: UserService, public route: ActivatedRoute,
              public transaction: OnlinePaymentService, public userProgress: UserProgressService, public router: Router) {
    this.courseId = this.route.snapshot.params.usercourse;
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0});
    if (this.service.sharedData === undefined) {
      this.router.navigate(['/layout/dashboard']);

    }
    if (this.courseId) {
      this.userProgress.getCourse(this.courseId, this.userservice.userDetails.data.userId).subscribe(x => {
        this.percentage = parseFloat(x.courseCompletedPercentage.toFixed());
        this.CourseDetails = x.userData;
        this.CoursecompletedTime = x.completedTime;
      });
    }


  }

  clickCard(selectedCourse) {
    this.selectedCouserByUser = selectedCourse;
    this.selectedScreen = false;
    this.service.sendMessage(false);
    const data = {
      data: selectedCourse
    };
    this.router.navigate(['/course/course-videos', { usercourse: selectedCourse.userCourseId }], this.service.sharedData = selectedCourse);
  }


  startOver(selectedCourse) {
    this.selectedCouserByUser = selectedCourse;
    this.selectedScreen = false;
    this.service.sendMessage(false);
    const data = {
      data: selectedCourse
    };
    this.router.navigate(['/course/course-videos', { usercourse: selectedCourse.userCourseId }]);
  }

  resume(selectedCourse) {
    this.selectedCouserByUser = selectedCourse;
    this.selectedScreen = false;
    this.service.sendMessage(true);
    const data = {
      data: selectedCourse
    };
    this.router.navigate(['/course/course-videos', { usercourse: selectedCourse.userCourseId }]);
  }

}
