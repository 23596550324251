<ng-container [ngSwitch]="mode">
  <!-- password reset -->
  <ng-container *ngSwitchCase="'resetPassword'">
    <app-confirm-password-reset></app-confirm-password-reset>
  </ng-container>

  <!-- verify email address -->
  <ng-container *ngSwitchCase="'verifyEmail'">
    <app-email-verification></app-email-verification>
  </ng-container>

  <!-- default action -->
  <ng-container *ngSwitchDefault>
  <!-- shoe error -->
  </ng-container>
</ng-container>