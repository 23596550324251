
<mat-sidenav-container>
        <mat-sidenav-content>
                <div class="flex-grow-1 flex-md-grow-0 text-center text-md-left p-4">
                        <a class="text-center" href="#">
                                <img src="../../assets/images/ca-logo.svg" alt="CA Logo" class="nav-logo">
                        </a>
                </div>
                <section class="hero-section">
                        <div class="container-fluid container-lg">
                                <div class="mainDiv">
                                        <div class="row align-items-center">
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                        <div class="textContent text-center">
                                                                <h1
                                                                class="mat-display-1 text-light mb-4 learn">
                                                                Learn. Scale <br />Your Career.</h1>
                                                                <h1
                                                                class="mat-title text-light mb-4 mt-5 create">
                                                                Create Your <b>free</b> account
                                                                </h1>
                                                        </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                                        <div class="studentBox mt-5 p-2">
                                                                <h1 class="pt-4" style="color: #393185;">SIGN UP with Course Assessment
                                                                </h1>
                                                                <button mat-button class="google p-2 text-light" (click)="signupWithGoogle()">
                                                                   <img src="../../assets/images/google-icon.svg" class="img-fluid m-2" />
                                                                  SIGN UP with Google
                                                                </button>
                                                                <button mat-button class="facebook mt-3 p-2 text-light" (click)="signupWithFacebook()">
                                                                    <img src="../../assets/images/facebook.png" class="img-fluid m-2" />
                                                                    SIGN UP with Facebook
                                                                </button>
                                                                <span class="orSection"><hr class="line" flex />&nbsp;&nbsp;<p>or</p>&nbsp;&nbsp;<hr flex class="line" /></span>
                                                                <button mat-button class="email p-2" routerLink="/email-signup" routerLinkActive="active">SIGN UP with e-mail</button>
                                                                <p class="p-2 policies">By signing up, you agree to our <a [routerLink]="'/company/terms'" [routerLinkActive]="'active'">Terms & Conditions</a> and <a [routerLink]="'/company/privacy-policy'" [routerLinkActive]="'active'">Privacy Policies</a></p>
                                                                <hr/>
                                                                <p class="alreadyMember p-3">Already a user ? <span routerLink="/login" routerLinkActive="active">SIGN IN</span></p>

                                                        </div>
                                                </div>
                                        </div>
                                </div>
                        </div>
                        
                </section>

        </mat-sidenav-content>
</mat-sidenav-container>