<section class="header-section bg-background-dark p-4">
  <div class="container-fluid container-lg">
    <h1 class="mat-title text-light my-2">Checkout</h1>
  </div>
</section>

<section class="about-section mb-5">
  <div class="about-container container-fluid container-lg mt-4">
    <div class="row">
      <div class="col-md-8">
        <div class="bg-background-light rounded-lg p-3">
          <p class="mat-title text-accent">Billing Address</p>
          <form [formGroup]="billingAddress">
            <div class="row">
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Address Line 1</mat-label>
                  <input matInput formControlName="Address1" required>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Address Line 2</mat-label>
                  <input matInput formControlName="Address2" required>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>City</mat-label>
                  <input matInput formControlName="City" [matAutocomplete]="auto" class="active" required>
                  <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="billingAddress?.controls.City.invalid && billingAddress?.controls.City.touched">
                    Invalid city name</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Pincode / Zipcode</mat-label>
                  <input matInput formControlName="Pincode" minLength="6" required>
                  <mat-error
                    *ngIf="billingAddress?.controls.Pincode.invalid && billingAddress?.controls.Pincode.touched">
                    Invalid pincode / zipcode</mat-error>
                </mat-form-field>
              </div>

            </div>
            <div class="row">
              <div class="col-md-6">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Country</mat-label>
                  <input matInput formControlName="Country" pattern="[a-zA-Z ]*" required>
                </mat-form-field>
              </div>
              <div class="col-md-6">

                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>State </mat-label>
                  <input matInput formControlName="State" [matAutocomplete]="autoState" class="active" required>
                  <mat-autocomplete #autoState="matAutocomplete" >
                    <mat-option *ngFor="let option of filterStateOptions | async" [value]="option" >
                      {{ option }}
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error *ngIf="billingAddress?.controls.State.invalid && billingAddress?.controls.State.touched">
                    Invalid state name</mat-error>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
        <div class="mt-4">
          <mat-accordion class="mt-4" [togglePosition]="'before'" displayMode="flat" multi>
            <mat-expansion-panel class="bg-background-light mat-elevation-z0 order-details" expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title class="d-flex align-items-center">
                  <p class="m-0">Order Details</p>
                </mat-panel-title>
              </mat-expansion-panel-header>
  
              <div class="order-course-details pt-2 d-flex align-items-center" *ngFor="let course of cartDetails[0]">
                <img class="order-course-image" src="{{course.videoUrl}}" />
                <p class="text-light my-0 ml-3">{{course.courseName}}</p>
                <p class="text-light-disabled ml-auto">{{course.actualPrice | currency:'INR':'symbol-narrow'}}</p>
              </div>
  
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        
      </div>
      <div class="col-md-4 align-self-stretch">
        <div class="bg-background-light rounded-lg p-3 h-100 d-flex flex-column">
          <div class="d-flex">
            <p class="mat-title text-accent">Actual Price</p>
            <p class="mat-title text-light ml-auto">{{sharedDetails.actualPrice | currency:'INR':'symbol-narrow'}}</p>
          </div>
          <div class="d-flex">
            <p class="mat-title text-accent">Discount</p>
            <p class="mat-title text-light ml-auto">-{{sharedDetails.discount | currency:'INR':'symbol-narrow'}}</p>
          </div>
          <div class="d-flex">
            <p class="mat-title text-accent">Total</p>
            <p class="mat-title text-light ml-auto">{{sharedDetails.totalCost | currency:'INR':'symbol-narrow'}}</p>
          </div>
          <button mat-flat-button color="accent" class="mt-auto w-100" (click)="clickPayment()">Continue Payment</button><br>
          <button mat-stroked-button class="mt-4 w-100 border-medium border-light" (click)="BacktoCart()">Back to My Cart</button>
        </div>
      </div>
    </div>
  </div>
</section>