import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OnlinePaymentService {

  constructor(private http: HttpClient) { }

  url = environment.serverUrl  + '/transaction';
  public orderId;
  public transactionDetails;
  public billingAddress;
  public transactionLength;


  getTransactionDetails(userId){
    const user = {
      userId
    };

    return this.http.get<any>(this.url + '/info' , {params: user})
    .pipe(
      map(result => {
        return{
          transactionDetails : result.data.map(data => {
            return{
              userTransactionId : data.userTransactionId,
              billingAddress : data.billingAddress,
              payment : data.payment,
              totalCost : data.totalCost,
              createdAt : this.dateFormation(new Date(data.createdAt)),

              coursePurchaseds : data.coursePurchaseds.map(purchasedCourse => {
                return{
                  courseId : purchasedCourse.courseId,
                  courseName : purchasedCourse.Course.courseName,
                  introVideo : purchasedCourse.Course.thumbnailImage,
                  thumbnailImage : purchasedCourse.Course.thumbnailImage,
                  backgroundImage: purchasedCourse.Course.backgroundImage,
                  courseIntroVideo: purchasedCourse.Course.courseIntroVideo,
                  price : purchasedCourse.Course.price,
                  Instructors : purchasedCourse.Course.Instructors.map(instructor => {
                    return{
                      firstName : instructor.firstName,
                      lastName : instructor.lastName
                    };
                  })

                };
              }),

              courseCount : data.coursePurchaseds.length

            };
          })

        };
      })
    );
  }

  dateFormation(date) {
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return date.getDate().toString().padStart(2, '0') + '-' + month[date.getMonth()] + '-' + date.getFullYear();
   }

  createUserTransaction(userTransaction)
  {
    return this.http.post<any>(this.url, userTransaction);
  }

  razorpayPayment(emailId){

    const user = {
      emailId
    };
    return this.http.get<any>(this.url + '/order' , {params: user});
  }

  getTransaction(transactionId) {
    return  this.http.get<any>(this.url + `/${transactionId}`).pipe(
      map(data => {
        return {

          transactionDetail : data.data.map(transaction => {

            return {
              userTransactionId : transaction.userTransactionId,
              transactionDate :  this.dateFormation(new Date(transaction.createdAt)),
              totalCost : transaction.totalCost,
              billingAddress : transaction.billingAddress,
              coursesPurchased: transaction.coursePurchaseds,
              payment: transaction.payment,
              paymentId: transaction.paymentId
            };
          })
        };
      })
    );
  }


}
