
<mat-sidenav-container>
    <mat-sidenav-content>
        <div class="flex-grow-1 flex-md-grow-0 text-center text-md-left p-4">
            <a class="text-center" href="#">
                <img src="../../assets/images/ca-logo.svg" alt="CA Logo" class="nav-logo">
            </a>
        </div>
        <section class="hero-section">
            <div class="container-fluid container-lg">
                <div class="mainDiv">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 my-4 order-0 text-center">
                            <h1 class=" text-light mt-5 learn">Clearing your career <br/> runways for take-off</h1>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 my-4 mt-md-5 wordings text-right text-sm-center">
                            <form [formGroup]="emailUserForm">
                                <div class="studentBox mt-5 text-center">
                                    <h1 class="pt-4 text-light"><b>SIGN IN with e-mail</b></h1>
                                    <div class="form-layout">
                                        <div class="row " class="email">
                                            <div class="md-col-12">
                                                <mat-form-field appearance="standard">
                                                    <mat-label>e-mail</mat-label>
                                                    <input name="email" matInput formControlName="email" required tabindex="1">
                                                    <mat-error
                                                        *ngIf="emailUserForm.controls.email.invalid && emailUserForm.controls.email.touched ">
                                                        Please enter a valid e-mail address
                                                    </mat-error>
                                                    <mat-error *ngIf="emailUserForm.hasError('required')">
                                                        e-mail is <strong>required</strong>
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row" class="password">
                                            <div class="md-col-12">
                                                <mat-form-field appearance="standard">
                                                    <mat-label>Password</mat-label>

                                                    <input matInput type="password" formControlName="password" required
                                                        [type]="hide ? 'password' : 'text'" tabindex="2" class="text-white">
                                                    <button type="button" mat-icon-button matSuffix (click)="hide = !hide"  tabindex = "-1" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                                        <mat-icon class="text-white">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                                    </button>
                                                    <mat-error *ngIf="emailUserForm.hasError('required', 'password')">
                                                        Please enter your password
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>

                                        <button type="submit" mat-button class="signUpBtn mt-3" (click)="onSubmit()" tabindex="3">SIGN IN</button>
                                        <span class="forgetPass text-light" routerLink="/forget-password" routerLinkActive="active" tabindex="4">Forgot password?</span>

                                    </div>

                                </div>
                            </form>
                              
                        </div>
                    </div>
                </div>
            </div>   

        </section>
    </mat-sidenav-content>
</mat-sidenav-container>