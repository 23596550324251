import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { SkeletonLoaderComponent } from './shared/components/skeleton-loader/skeleton-loader.component';
import { CommonModule } from '@angular/common';
import { ModalWindowComponent } from './shared/components/modal-window/modal-window.component';
import { FeedbackRatingComponent } from './shared/components/feedback-rating/feedback-rating.component';
import { RatingPreviewComponent } from './shared/components/rating-preview/rating-preview.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { VideoScreenPopupComponent } from './shared/components/video-screen-popup/video-screen-popup.component';
import { VrScreenComponent } from './shared/components/vr-screen/vr-screen.component';
import { SampleCertificateComponent } from './shared/components/sample-certificate/sample-certificate.component';
import { AboutUsComponent } from './shared/components/about-us/about-us.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './shared/components/header/header.component';
import { LayoutContainerComponent } from './shared/components/layout-container/layout-container.component';
import { RatingComponent } from './shared/components/rating/rating.component';
import { RevisitVideoComponent } from './shared/components/revisit-video/revisit-video.component';



@NgModule({
  declarations: [
    SkeletonLoaderComponent,
    ModalWindowComponent,
    FeedbackRatingComponent,
    RatingPreviewComponent,
    VideoScreenPopupComponent,
    VrScreenComponent,
    SampleCertificateComponent,
    AboutUsComponent,
    FooterComponent,
    HeaderComponent,
    LayoutContainerComponent,
    RatingComponent,
    RevisitVideoComponent,

  ],
  imports: [MaterialModule, RouterModule, CommonModule, NgbModule],
  exports: [SkeletonLoaderComponent, HeaderComponent, FooterComponent, LayoutContainerComponent, RatingComponent],
})
export class SharedModule { }
