<section class="header-section bg-background-dark p-4">
  <div class="container-fluid container-lg">
    <h1 class="mat-title text-light my-2">Transactions History</h1>
  </div>
</section>

<section class="deatil-section">
  <div class="container-fluid container-lg">
    <div class="d-flex align-items-center pt-4">
      <h3 class="mat-title text-light-disabled">{{coursePurchasedLength}} Transactions</h3>
      <mat-form-field appearance="outline" class="ml-auto">
        <mat-label>Choose an option</mat-label>
        <mat-select (selectionChange)="onChange($event.value);" [(value)]="selected" disableOptionCentering
          panelClass="myPanelClass">
          <mat-option value="Past 1 months">Past 1 months</mat-option>
          <mat-option value="Past 3 months">Past 3 months</mat-option>
          <mat-option value="Past 6 months">Past 6 months</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</section>

<section class="cart-section">
  <div class="container-fluid container-lg py-3 border-top-small border-bottom-small border-light-divider">
    <mat-accordion [togglePosition]="'before'" displayMode="flat" multi>
      <mat-expansion-panel class="mb-3" expanded="false" *ngFor="let transaction of transactionData">
        <mat-expansion-panel-header>
          <mat-panel-title class="d-flex align-items-center">
            <p class="m-0">{{transaction.courseCount}} Course purchased</p>
            <p class="text-light-disabled my-0 ml-auto mr-0 mr-sm-5">{{transaction.createdAt}}</p>
          </mat-panel-title>
          <mat-panel-description class="d-none d-sm-flex align-items-center">
            <p class="my-0 ml-auto mr-2">₹ {{transaction.totalCost}} &nbsp;&nbsp;{{transaction.payment}}</p>
            <button mat-stroked-button (click)="clickInvoice(transaction)">Receipt</button>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="purchased-course-details pt-2 d-flex align-items-center" *ngFor="let course of transaction.coursePurchaseds">
          <img class="purchased-course-image" src="{{course.thumbnailImage}}" />
          <p class="text-light my-0 ml-3">{{course.courseName}}</p>
          <p class="text-light-disabled ml-auto">{{course.price | currency:'INR':'symbol-narrow'}}</p>
        </div>

      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>