<div class="row">
    <div class="col-md-8 col-lg-8">
        <h3 class="m-0" fxFlex="initial">{{getData.lessonName}}</h3>
    </div>
    <div class="col-md-4 col-lg-4 text-right">
        <button mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
    
</div>
  

<div class="container-fluid mt-3" *ngIf="getData.courseType != 'pdf'">
    <video class="rounded-lg" width="100%" controlsList="nodownload" controls>
        <source src="{{ videoLink }}" type="video/mp4" />
    </video>
</div>
<!-- <div class="container-fluid mt-3" *ngIf="getData.courseType === 'video' && getData.fullHdVideo">
    <video class="rounded-lg" width="100%" controlsList="nodownload" controls>
        <source src="{{ getData.fullHdVideo }}" type="video/mp4" />
    </video>
</div>
<div class="container-fluid mt-3" *ngIf="getData.courseType === 'video' && getData.hdVideo">
    <video class="rounded-lg" width="100%" controlsList="nodownload" controls>
        <source src="{{ getData.hdVideo }}" type="video/mp4" />
    </video>
</div> -->
<div class="pdf mt-3" *ngIf="getData.courseType === 'pdf'">

    <iframe #pdf width="100%" height="100%"></iframe>
</div>


