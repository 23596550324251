import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from 'firebase';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  [x: string]: any;
  error: any;
  public getMode: any;


  constructor(public authservice: AuthService, public service: UserService, private router: Router) {
    this.authservice.deleteToken();
   }

  ngOnInit(): void {
  }

  async loginWithGoogle() {
    let loginUser = await this.authservice.loginWithGoogle()
    if (loginUser.additionalUserInfo.isNewUser === false) {
      this.service.getUserMode(loginUser.additionalUserInfo.profile["email"]).subscribe(data => {
        if (data.data.mode === 'Google') {
          const userDetails = {
            userfirstName: loginUser.additionalUserInfo.profile["given_name"],
            userlastName: loginUser.additionalUserInfo.profile["family_name"],
            userEmail: loginUser.additionalUserInfo.profile["email"],
          }

          const usermode = "Google"
          this.service.getLoginDetails(userDetails).subscribe(result => {
            this.service.setItem(result.token)
            this.service.userDetails = result;
            this.router.navigate([''])
          })
        } else {
          Swal.fire({
            icon: 'error',
            text: "You have created an account through E-mail sign-up, you need to sign-in using 'Sign-in with E-mail' option",
            showConfirmButton: true,
            heightAuto: false,
          })
        }
      })



      // this.router.navigate(['/layout/dashboard'])
    } else {
      Swal.fire({
        icon: 'error',
        text: "User does not exists.",
        showConfirmButton: true,
        heightAuto: false,
      })

      loginUser.user.delete()
    }

  }

  async loginWithFacebook() {
    let fbLoginUser = await this.authservice.loginWithFacebook()
    if (fbLoginUser.additionalUserInfo.isNewUser === false) {
      this.service.getUserMode(fbLoginUser.additionalUserInfo.profile["email"]).subscribe(data => {
        if (data.data.mode === 'Facebook') {
          const userDetails = {
            userfirstName: fbLoginUser.additionalUserInfo.profile["given_name"],
            userlastName: fbLoginUser.additionalUserInfo.profile["family_name"],
            userEmail: fbLoginUser.additionalUserInfo.profile["email"],
          }

          const usermode = "Facebook"
          this.service.getLoginDetails(userDetails).subscribe(result => {
            this.service.setItem(result.token)
            this.service.userDetails = result;
            this.router.navigate([''])
          })
        } else {
          Swal.fire({
            icon: 'error',
            text: "You have created an account through E-mail sign-up, you need to sign-in using 'Sign-in with E-mail' option",
            showConfirmButton: true,
            heightAuto: false,
          })
        }
      })



      // this.router.navigate(['/layout/dashboard'])
    } else {
      Swal.fire({
        icon: 'error',
        text: "User does not exists.",
        showConfirmButton: true,
        heightAuto: false,
      })

      fbLoginUser.user.delete()
    }

  }

  

  emailSignin() {
    this.router.navigate(['/email-login'])
  }

  studentSignUp() {
    this.router.navigate(['/sign-up'])
  }
}
