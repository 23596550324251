import { Component, Input, OnInit, HostBinding } from '@angular/core';
import { WishlistService } from 'src/app/services/wishlist.service';
import { UserService } from 'src/app/services/user.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { MyCartService } from 'src/app/services/my-cart.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { AuthService } from 'src/app/services/auth.service';
import { OnlinePaymentService } from 'src/app/services/online-payment.service';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {

  public userDetails: { userId: any; };
  public databaseUser: any[];
  public wishlistLength: number;
  public CourseDetails: any = [];
  public cartLength;
  public wishlistUser: any;
  public isWishlistLoaded = false;

  @HostBinding('class.flex-fill') autoHeight = true;


  constructor(public wishlist: WishlistService, public userservice: UserService,
              public cartService: MyCartService, private router: Router) {

  }

  ngOnInit(): void {
    this.userDetails = this.userservice.userDetails.data;
    this.isWishlistLoaded = false;
    this.wishlist.getWishlistCourse(this.userDetails.userId).subscribe((data) => {
      this.databaseUser = data.wishlistData;
      // console.log(data.wishlistData);
      this.wishlistLength = data.wishlistData.length;
      this.isWishlistLoaded = true;
    });
  }

  removeWishlist(index: number, wishlist: any) {
    const wishlistUser = this.userservice.userDetails.data.userId;
    const wishlistCourse = wishlist.courseId;
    this.wishlist.removeWishlist(wishlistUser, wishlistCourse).subscribe(() => {
      this.databaseUser.splice(index, 1);
      this.wishlistLength = this.databaseUser.length;
    });
  }

  moveToCart(index: number, wishlist: any) {
    const wishlistUser = this.userservice.userDetails.data.userId;
    const wishlistCourse = wishlist.courseId;

    this.cartService.addToCart(wishlistUser, wishlistCourse).subscribe(data => {
      if (data.success) {
        this.databaseUser.splice(index, 1);
        this.cartService.increaseCartCount();
        this.wishlistLength--;
        this.wishlist.removeWishlist(wishlistUser, wishlistCourse).subscribe(() => { });
      }
    }, error => {
        Swal.fire({
          icon: 'error',
          text: error.error.message,
          showConfirmButton: true,
          heightAuto: false,
          allowOutsideClick: false,
        });
      });
  }

  clickCourse(data){
    this.router.navigate(['/course-details'], { queryParams: { courseId: data.courseId } });
  }

}
