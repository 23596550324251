import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CourseVideosComponent } from 'src/app/course/course-videos/course-videos.component';

@Component({
  selector: 'app-video-screen-popup',
  templateUrl: './video-screen-popup.component.html',
  styleUrls: ['./video-screen-popup.component.scss']
})
export class VideoScreenPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CourseVideosComponent>) { }

  ngOnInit(): void {
  }

  startOver(){
    this.dialogRef.close({success: true});
  }

  resume(){
    this.dialogRef.close({success: false});
  }

}
