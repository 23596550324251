import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, searchValue): any {

    if (!searchValue) {
      return value; 
    }

    let filterArray = value.filter((v) => v.courseName.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 )
    
    return filterArray;

  }
}