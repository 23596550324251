<mat-toolbar color="primary" class=" fixed-top">
    <mat-toolbar-row class="container-fluid d-flex align-items-center custom-toolbar">
        <div class="flex-grow-1 flex-md-grow-0 text-center text-md-left p-4">
            <a class="text-center" href="#">
                <img src="../../assets/images/ca-logo.svg" alt="CA Logo" class="nav-logo">
            </a>        
        </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container class="mt-n5">
    <mat-sidenav-content>
        <section class="hero-section bg-background-dark">
            <div class="container-fluid container-lg lg-device">
                <div class="mainDiv">
                    <div class="row ">
                        <div class="col-lg-12 col-md-12 mt-5 ">
                            <form [formGroup]="emailUserForm">
                                <div class=" studentBox">
                                    <div class="form-layout ">
                                        <div class="row " class="email">
                                            <div class="md-col-12">
                                                <h1 class="text-white"><b>FORGET PASSWORD ?</b></h1>
                                                <p class="text-white">Please enter your e-mail address below and we will send you information to recover your account.</p>
                                                <mat-form-field appearance="standard">
                                                    <mat-label>e-mail</mat-label>
                                                    <input name="email" matInput formControlName="email" required>
                                                    <mat-error *ngIf="emailUserForm.controls.email.invalid">
                                                        Please enter a valid e-mail address
                                                    </mat-error>
                                                    <mat-error *ngIf="emailUserForm.hasError('required')">
                                                        e-mail is <strong>required</strong>
                                                      </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <button type="submit" mat-button class="resetBtn mt-5" (click)="submitForm()">Reset Password</button>
                                        <!-- <h4 class="terms"> <a class="policies">Forget password?</a></h4> -->
                                    </div>
                                </div>
                    
                            </form>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid sm-device">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12 mt-5 ">
                       
                            <form [formGroup]="emailUserForm">
                                <div class=" studentBox">
                                    <div class="form-layout ">
                                        <div class="row " class="email">
                                            <div class="md-col-12">
                                                <h1 class="text-white"><b>FORGET PASSWORD ?</b></h1>
                                                <p class="text-white">Please enter your e-mail address below and we will send you information to recover your account.</p>
                                                <mat-form-field appearance="standard">
                                                    <mat-label>e-mail</mat-label>
                                                    <input matInput formControlName="email" required>
                                                    <mat-error *ngIf="emailUserForm.controls.email.invalid">
                                                        Please enter a valid e-mail address
                                                    </mat-error>
                                                    <mat-error *ngIf="emailUserForm.hasError('required')">
                                                        e-mail is <strong>required</strong>
                                                      </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <!-- <mat-card class="resetBtn mt-5">
                                            <h3 type="submit" (click)="submitForm()">Reset Password</h3>
                                        </mat-card> -->
                                        <button type="submit" mat-button class="resetBtn mt-5" (click)="submitForm()">Reset Password</button>
                                        <!-- <h4 class="terms"> <a class="policies">Forget password?</a></h4> -->
                                    </div>
                                </div>
                    
                            </form>
                    
                    </div>
                   
                </div>

            </div>
            <!-- <div class="imgPlane ">
                <img src="../../assets/images/plane_flying.svg" class="planeImage" />
            </div> -->

        </section>

    </mat-sidenav-content>
</mat-sidenav-container>