import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CourseDetailsComponent } from 'src/app/home/course-details/course-details.component';

@Component({
  selector: 'app-vr-screen',
  templateUrl: './vr-screen.component.html',
  styleUrls: ['./vr-screen.component.scss']
})
export class VrScreenComponent implements OnInit {

 
  public displayedColumns: string[] = ['position', 'city'];
  public showColumns: string[] = ['position', 'name'];
  public dataSource;
  public partnerSource = [];
  public showURL: boolean = false;

  constructor(public dialogRef: MatDialogRef<CourseDetailsComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    // console.log(data)
    this.dataSource = data.vrCourses
   }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }

  showUrl(element){
    this.showURL = true;
    this.partnerSource = element

  }

}
