import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { threadId } from 'worker_threads';
import { passwordValidator, MyErrorStateMatcher } from './validator';
import Swal from 'sweetalert2';
import { map, startWith } from 'rxjs/operators';
import { CityService } from 'src/app/services/city.service';
import { MyCartService } from 'src/app/services/my-cart.service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { OnlinePaymentService } from 'src/app/services/online-payment.service';
import { Router } from '@angular/router';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';



@Component({
  selector: 'app-myprofile',
  templateUrl: './myprofile.component.html',
  styleUrls: ['./myprofile.component.scss']
})
export class MyprofileComponent implements OnInit {

  userDetails;
  public databaseUser;
  public getCity = [];
  public userForm: FormGroup;
  public isEdit = false;
  public state = [];
  public filteredState = [];
  changeButton = false;
  public userName;
  public firstLetter;
  public cart = false;
  public cartBadge;
  public categoryDetails = [];
  public transactionDetails = [];
  public isShowDiv = false;
  public cartDetails;
  public wishlistUser;
  public disabled = false;
  public hide = true;
  public cityName;
  public values;
  private cd: ChangeDetectorRef;
  matcher = new MyErrorStateMatcher();
  filteredOptions: Observable<string[]>;
  filterStateOptions: Observable<string[]>;
  @ViewChild('fileInput') el: ElementRef;
  @ViewChild('imgURL', {static: false}) imgURL: ElementRef;
  imageUrl: any = '../../../assets/images/uploadPic.png';
  editFile = true;
  removeUpload = false;
  public imgUpload: any = '';

  constructor(public service: CommonServiceService, public userservice: UserService, public wishlist: WishlistService,
              public authservice: AuthService, public cartService: MyCartService, public cityService: CityService,
              public searchService: SearchFilterService, public transaction: OnlinePaymentService,
              public router: Router, private formBuilder: FormBuilder) {

    // this.userDetails = this.userservice.userDetails.data;
    const userId = this.userservice.userDetails.data.userId;
 
    this.userservice.getUserProfile(userId).subscribe(data => {
      this.userDetails = data.userData[0];
      if (this.userDetails.profileImage != ''){
        this.imageUrl = this.userDetails.profileImage;
      
       
      }
      this.userForm = this.formBuilder.group({
        firstName :  this.userDetails ? [{value : this.userDetails.firstName , disabled: true}] : [''] ,
        lastName :  this.userDetails ? [{value : this.userDetails.lastName , disabled: true}] : [''] ,
        title : this.userDetails ? [this.userDetails.title, [Validators.required]] : [''],
        email : this.userDetails ? [{value : this.userDetails.email , disabled: false}] : [''],
        currentPass :  this.userDetails.mode === 'Email' ? [{value : '', disabled: false}] : [{value: '', disabled: true}],
        newPass :  this.userDetails.mode === 'Email' ? [{value : '', disabled: false}, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')] : [{value: '', disabled: true}],
        confirmPass :  this.userDetails.mode === 'Email' ? 
                      [{value : '', disabled: false}, passwordValidator] : [{value: '', disabled: true}],
        phNo :  (this.userDetails.phNo != 0 && this.userDetails.phNo) ? 
                      [this.userDetails.phNo, [Validators.maxLength(10), Validators.minLength(10)]] : 
                      ['', [Validators.maxLength(10), Validators.minLength(10)]],
        address : this.userDetails ? [this.userDetails.address] : [''],
        city : this.userDetails ? [this.userDetails.city] : [''],
        company : this.userDetails ? [this.userDetails.company] : [''],
        country : this.userDetails ? [{value: 'India', disabled: true}] : [{value: 'India', disabled: true}],
        postcode : (this.userDetails.postcode !== 0 && this.userDetails.postcode) ? [this.userDetails.postcode, [Validators.pattern('[0-9]{6}')]] : ['', [Validators.pattern('[0-9]{6}')]],
        state : this.userDetails ? [this.userDetails.state] : [''],
        file: this.userDetails ? [this.userDetails.profileImage] : ['']
      });

      // this.imgUpload = this.convertToBase64("https://smartgcpro.s3.ap-south-1.amazonaws.com/images/user_34.jpg")
      // this.imgUpload = this.convertToBase64("../../../assets/images/uploadPic.png")
    
      this.filteredOptions = this.userForm.get('city').valueChanges
      .pipe(
      startWith(''),
      map(val => this.filter(val))
      );

      this.filterStateOptions = this.userForm.get('state').valueChanges
      .pipe(
        startWith(''),
        map(val => this.filterState(val))
      );
    });

    this.cityService.getCity().subscribe(city => {
      city.city.forEach(x => {
        this.getCity.push(x);
        if (!this.state.some((a) => a === x.state)){
          this.state.push(x.state);
        }
      });
    });
  }


  uploadFile(event) {
    const reader = new FileReader(); // HTML5 FileReader API
    const file = event.target.files[0];
    // this.imgUpload = file;
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.imgUpload = reader.result;
        this.userForm.patchValue({
          file: reader.result
        });
        this.editFile = false;
        this.removeUpload = true;
      };
      // ChangeDetectorRef since file is loading outside the zone
      // this.cd.markForCheck();
    }
  }

  removeUploadedFile() {
    const newFileList = Array.from(this.el.nativeElement.files);
    this.imageUrl = '../../../assets/images/uploadPic.png';
    this.imgUpload = '';
    this.editFile = true;
    this.removeUpload = false;
    // this.userForm.patchValue({
    //   file: [null]
    // });
  }

  clickCourse(selectedCourse){
    this.service.sharedData = selectedCourse;
    this.router.navigate(['/course-details'], {queryParams: {courseId: selectedCourse.courseId} });
  }

  toFormData( formValue ) {

    const formData = new FormData();

    for ( const key of Object.keys(formValue) ) {
      const value = formValue[key];
      const valueType = typeof value;
      if (value){
        if (value.length) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
      }else{
        formData.append(key, JSON.stringify(''));
      }

    }

    return formData;
  }

  compare(a, b) {
    const bandA = a.city.toUpperCase();
    const bandB = b.city.toUpperCase();

    let comparison = 0;
    if (bandA > bandB) {
    comparison = 1;
    } else if (bandA < bandB) {
    comparison = -1;
    }
    return comparison;
  }


  saveChanges(){
      const editUserDetails = {
        userfirstName: this.userForm.get('firstName').value,
        userlastName: this.userForm.get('lastName').value,
        title: this.userForm.get('title').value,
        useroldPassword: this.userForm.get('currentPass').value,
        usernewPassword: this.userForm.get('newPass').value,
        userCompany : this.userForm.get('company').value,
        userphNo : this.userForm.get('phNo').value,
        userAddress : this.userForm.get('address').value,
        userCity : this.userForm.get('city').value,
        userPostcode : this.userForm.get('postcode').value,
        userCountry : this.userForm.get('country').value,
        userState : this.userForm.get('state').value,
        image : this.imgUpload
      };
      console.log(editUserDetails)

      if (!this.userForm.valid){
        Swal.fire({

          icon: 'error',
          text: 'Oops ! Something wasn\'t right',
          footer: '<div>Invalid form field. Please try again</div>',
          showConfirmButton: true,
          heightAuto: false,
          allowOutsideClick: false,
        });
      }else{
        if (editUserDetails.usernewPassword === ''){
          this.userservice.updateUserProfile(this.userDetails.userId, editUserDetails).subscribe(result => {
            if (result.success){
              this.changeButton = true;
              this.userservice.userDetails.data = result.data;
            }
          });
        }else{
          this.userservice.updateUserProfile(this.userDetails.userId, editUserDetails).subscribe(result => {
            if (result.success){
              this.userservice.userDetails.data = result.data;
              firebase.auth()
        .signInWithEmailAndPassword(this.userDetails.email, editUserDetails.useroldPassword)
        .then((userCredential) => {
          // let data = this
            userCredential.user.updatePassword (editUserDetails.usernewPassword);
            this.changeButton = true;

        }).catch(err => {
          Swal.fire({
            icon: 'error',
            text: err,
            showConfirmButton: true,
            heightAuto: false,
            allowOutsideClick: false,
          });
        });
            }
          });

        }

      }
  }

 

  ngAfterViewInit(){
   
  }

  ngOnInit(): void {
    
    
   
  }

  filter(val: string): string[] {
    return this.getCity.map(x => x.city).filter(option =>
      option.toLowerCase().includes(val.toLowerCase()));
  }

  filterState(val: string): string[]{
    return this.state.map(x => x).filter(option =>
      option.toLowerCase().includes(val.toLowerCase()));
  }

}
