import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { database } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class SearchFilterService {

  url = environment.serverUrl  + '/category';

  constructor(private http: HttpClient) { }

  getCategoryDetails(id){
    const userId = {
      'userId': id,
    }
    return this.http.get<any>(this.url, { params: userId })
    .pipe(
      map((results)=>{
        return{
          CategoryData : results.data.map((data)=>{
            return{
              categoryName : data.categoryName,
              categoryDesc : data.categoryDesc,
              courses : data.Courses.map((course)=>{
                return{
                  courseId : course.courseId,
                  courseName : course.courseName,
                  description : course.courseDesc,
                  videoUrl : course.thumbnailImage,
                  backgroundImage: course.backgroundImage,
                  courseIntroVideo: course.courseIntroVideo,
                  price : course.price,
                  strikedPrice : data.strikedPrice,
                  longDesc : course.description,
                  duration : course.duration,
                  totalRating : course.totalRating,
                  averageRating : course.averageRating,
                  userCourseId : course.userCourseId,
                  courseType : course.courseType,
                  // instructor : course.Instructors.map((instdetail,index)=>{
                  //   return{
                  //     firstName : instdetail.firstName,
                  //     lastName : instdetail.lastName,
                  //     designation : instdetail.designation,
                  //     instructorDescription : instdetail.description,
                  //     courseInsructor : instdetail.CourseInstructor.courseId,
    
                  //   }
                    
                  // }),
                  // courseLearning : course.courseLearnings.map((leaningDetail,index)=>{
                  //   return{
                  //     learningText : leaningDetail.learningText,
                  //   }
                  // }),
                  // coursePreReqs : course.coursePreReqs.map(PreReq=>{
                  //   return{
                  //     preReqText : PreReq.preReqText
                  //   }
                  // }),
                  // acrreditations : course.acrreditations.map(acrreditations=>{
                  //   return{
                  //     accreditationsName : acrreditations.accreditationsName,
                  //     accreditationsLogo : acrreditations.accreditationsLogo,
                  //   }
                  // }),
                  // CourseModules : course.CourseModules.map(CourseModules=>{
                  //   return{
                  //     courseModuleId : CourseModules.courseModuleId,
                  //     courseId : CourseModules.courseId,
                  //     moduleName : CourseModules.moduleName,
                  //     CourseLessons : CourseModules.CourseLessons.map(CourseSubModules=>{
                  //       return{
                  //         CourseLessonId : CourseSubModules.CourseLessonId,
                  //         courseModuleId : CourseSubModules.courseModuleId,
                  //         lessonName : CourseSubModules.lessonName,
                  //         lessonUrl : CourseSubModules.lessonUrl,
                  //         duration : CourseSubModules.duration,
                  //       }
                  //     })
                  //   }
                  // }),
                  // userRatings : course.userRatings.map((userRating)=>{
                  //   return{
                  //     rating : userRating.rating,
                  //     reviews : userRating.reviewComments,
                  //     firstName : userRating.User.firstName,
                  //     lastName : userRating.User.lastName,
                  //     profileImage : userRating.User.profileImage,
    
                  //   }
                  // }),
                  // courseCertifications : course.courseCertifications.map(courseCertifications=>{
                  //   return{
                  //     courseId : courseCertifications.courseId,
                  //     certificationHead : courseCertifications.certificationHead,
                  //   }
                  // }),
                  // courseAreas : course.courseAreas.map(courseAreas=>{
                  //   return{
                  //     courseAreaName : courseAreas.courseAreaName,
                  //   }
                  // })



                }
              })
            }

          })
        }
      })
    )
  }
}
