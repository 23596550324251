import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {
  public mode;
  public actionCode;

  constructor(public activatedActivated: ActivatedRoute,public router: Router,public authService: AuthService) {
    this.mode = this.activatedActivated.snapshot.queryParams['mode']
    this.actionCode = this.activatedActivated.snapshot.queryParams['oobCode']
    this.authService.handleVerifyEmail(this.actionCode)
   }

  ngOnInit(): void {
    
  }

}
