<section class="header-section bg-background-dark p-4">
    <div class="container-fluid container-lg">
        <h1 class="mat-title text-light my-2">My Wishlist</h1>
    </div>
</section>

<section class="transaction-section">
    <div class="container-fluid container-lg border-bottom-small border-light-divider" >
      <div class="text-center my-5" >
        <svg width="100" height="100" viewBox="0 0 24 24">
            <path
                d="M12 2a10 10 0 1010 10A10 10 0 0012 2zm0 18a8 8 0 118-8 8.011 8.011 0 01-8 8zm3.88-11.71L10 14.17l-1.88-1.88a1 1 0 00-1.41 1.41l2.59 2.59a1 1 0 001.41 0L17.3 9.7a1 1 0 000-1.41 1.008 1.008 0 00-1.42 0z"
                fill="var(--accent)" />
        </svg>
        <h3 class="text-light" >Your purchase has been completed successfully !!</h3>
        <button mat-flat-button color="accent" routerLink="/layout/dashboard" >Go to Course</button>
      </div>
    </div>
</section>

<section class="cart-section">
    <div class="container-fluid container-lg py-3 border-top-small border-bottom-small border-light-divider">
      <mat-accordion [togglePosition]="'before'" displayMode="flat" multi>
        <mat-expansion-panel class="mb-3" expanded="false">
          <mat-expansion-panel-header>
            <mat-panel-description class="d-none d-sm-flex align-items-center">
              <p class="mt-3">Order Details</p>
              <p class="my-0 ml-auto mr-2">{{(sharedDetails?.totalCost || 0) | currency:'INR':'symbol-narrow'}}</p>
            </mat-panel-description>
          </mat-expansion-panel-header>
  
          <div class="purchased-course-details pt-2 d-flex align-items-center" *ngFor="let course of cartDetails[0]">
            <img class="purchased-course-image" src="{{course.videoUrl}}" />
            <p class="text-light my-0 ml-3">{{course.courseName}}</p>
            <p class="text-light-disabled ml-auto">{{course.price | currency:'INR':'symbol-narrow'}}</p>
          </div>
  
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </section>


<!-- <section class="testmonial-section">
    <div class="container-fluid container-lg p-4">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <mat-accordion [togglePosition]="'before'">
                    <mat-expansion-panel expanded='true' class="pb-4">
                        <mat-expansion-panel-header class="d-flex">
                            <mat-panel-title class="lg-title">
                                <h4 style="color: #FFFFFFDE;" class="mt-1"><b>Order Details</b></h4>
                                <h4 style="color: #FFFFFFDE;" class="ml-auto pt-1"><b>₹ {{sharedDetails.totalCost}}</b>
                                </h4>
                            </mat-panel-title>

                        </mat-expansion-panel-header>
                        <div class="order-courseDetail mt-1">
                            <div class="row courses" *ngFor="let x of cartDetails[0]">
                                <div class="courseImg  col-md-10 col-lg-10 col-xl-10">
                                    <img class="img-fluid ml-2" src="{{x.videoUrl}}" />
                                    <div class="courseName ">{{x.courseName}}</div>
                                    <p class="courseInstructor mt-1"> By
                                        {{x.instructor.firstName}}&nbsp;{{x.instructor.lastName}}</p>

                                </div>
                                <div class=" courseDetails col-md-2 col-lg-2 col-xl-2 text-right">
                                    <div class="coursePrice">₹ {{x.actualPrice}}</div>

                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

            </div>
        </div>
    </div>
</section> -->