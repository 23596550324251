import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './layout.component';
import { AuthGuard } from '../shared/guard/auth-guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { DashbordCourseDetailsComponent } from './dashbord-course-details/dashbord-course-details.component';
import { MyCartComponent } from './my-cart/my-cart.component';
import { componentFactoryName } from '@angular/compiler';
import { WishlistComponent } from './wishlist/wishlist.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { OnlinePaymentComponent } from './online-payment/online-payment.component';
import { TransactionSuccessComponent } from './transaction-success/transaction-success.component';
import { fail } from 'assert';

const routes: Routes = [

  {
    path : 'layout',
    component : LayoutComponent,
    // canActivate : [AuthGuard]
    children: [
      {
        path : 'dashboard',
        component : DashboardComponent,
        canActivate : [AuthGuard]
        },
        {
          path : 'myprofile',
          component : MyprofileComponent,
          canActivate : [AuthGuard]
        },
        {
          path : 'dashboard-course-details',
          component : DashbordCourseDetailsComponent,
          canActivate : [AuthGuard]
        },
        {
          path: 'my-cart',
          component : MyCartComponent,
          canActivate : [AuthGuard]
        },
        {
          path: 'wishlist',
          component: WishlistComponent,
          canActivate : [AuthGuard]
        },
        {
          path: 'transactions',
          component: TransactionsComponent,
          canActivate: [AuthGuard]
        },
        {
          path: 'transaction-success',
          component: TransactionSuccessComponent,
          canActivate: [AuthGuard]
        },
        {
          path: 'invoice',
          component: InvoiceComponent,
          canActivate: [AuthGuard]

        },
        {
          path: 'online-payment',
          component: OnlinePaymentComponent,
          canActivate: [AuthGuard]
        }
    ]
  },
















];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
