import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  url = environment.serverUrl  + '/user';
  ratingUrl = environment.serverUrl  + '/userRating';
  ansUrl = environment.serverUrl + '/question';
  userDetails;
  databaseUser;

  private userDetailsUpdated = new Subject<number>();


  constructor(private http: HttpClient,  private router: Router, public snackBar: MatSnackBar) { }

  getUserDetailsListener() {
    return this.userDetailsUpdated.asObservable();
  }

  updateUserDetails(userDetails) {
    this.userDetails = userDetails;
    this.userDetailsUpdated.next(this.userDetails);
  }

  getStoredUserDetails() {
    this.userDetailsUpdated.next(this.userDetails);
  }

  createUsers(user){
    return this.http.post<any>(this.url, user)
  }

  getUserDetails(userid){
    return this.http.get<any>(this.url + '/info' +`/${userid}`)
    .pipe(
      map((result =>{
        // console.log(result)
        return{
          userData : result.data.map((user,index) => {
            return{
              firstName : user.firstName,
              lastName : user.lastName,
              email : user.email,
              title: user.title,
              password : user.password,
              courses : user.Courses.map((courseDetails)=>{
                return{
                  courseId : courseDetails.courseId,
                  courseName : courseDetails.courseName,
                  courseDesc : courseDetails.courseDesc,
                  courseType: courseDetails.courseType,
                  rating : courseDetails.rating,
                  totalReviews : courseDetails.totalReviews,
                  introVideo : courseDetails.thumbnailImage,
                  thumbnailImage : courseDetails.thumbnailImage,
                  backgroundImage: courseDetails.backgroundImage,
                  courseIntroVideo: courseDetails.courseIntroVideo,
                  duration : courseDetails.duration,
                  price : courseDetails.price,
                  newDesc: courseDetails.newCourseDesc,
                  strikedPrice : courseDetails.strikedPrice,
                  certification : courseDetails.certification,
                  courseStatus : courseDetails.UserCourse.courseStatus,
                  userCourseId : courseDetails.UserCourse.userCourseId,
                  completionDate : courseDetails.UserCourse.completionDate,
                  totalTime : courseDetails.totalTime,
                  completedTime : courseDetails.completedTime,
                  percentageCompletion : (courseDetails.completedTime / courseDetails.totalTime ) * 100,
                  expired : courseDetails.expired,

                  // courseModules : courseDetails.CourseModules.map((courseModules)=>{
                  //   return{
                  //     moduleName : courseModules.moduleName,
                  //     orders : courseModules.orders,
                  //     CourseLessons : courseModules.CourseLessons.map((subModules)=>{
                  //       return{
                  //         CourseLessonId : subModules.CourseLessonId,
                  //         courseModuleId : subModules.courseModuleId,
                  //         lessonName : subModules.lessonName,
                  //         lessonUrl : subModules.lessonUrl,
                  //         duration : subModules.duration,
                  //       }
                  //     }),
                  //     questions : courseModules.questions.map((questions)=>{
                  //       return{
                  //         questionId : questions.questionId,
                  //         questionText : questions.questionText,
                  //         answers : questions.answers.map((ans)=>{
                  //           return{
                  //             answerId : ans.answerId,
                  //             questionId : ans.questionId,
                  //             answerText : ans.answerText,
                  //             isCorrect : ans.answerText,
                  //             selected : false,
                  //           }
                            
    
                  //         }), 
                  //       }
                        
                        
                  //     })
                  //   }
                  // }),
                  // exams : courseDetails.exams.map(quiz=>{
                  //   return{
                  //     examId : quiz.examId,
                  //     courseId : quiz.courseId,
                  //     examText : quiz.examText,
                  //     solutions : quiz.solutions.map(answer=>{
                  //       return{
                  //         solutionId : answer.solutionId,
                  //         examId : answer.examId,
                  //         solutionText : answer.solutionText,
                  //         isCorrect : answer.isCorrect,
                  //         selected : false,
                  //       }
                  //     })
                  //   }
                  // }),

                  // userRatings : courseDetails.userRatings.map(rating=>{
                  //   return{
                  //     courseId : rating.courseId,
                  //     rating : rating.rating,
                  //     reviewComments : rating.reviewComments,
                  //     userId : rating.userId,
                  //     userRatingId : rating.userRatingId,
                  //   }
                  // }),
                  // ExamResults : courseDetails.ExamResults.map(result=>{
                  //   return{
                  //     percentage : result.percentage,
                  //     userId : result.userId,
                  //   }
                  // })
                }
              }),
              
            }
          })
        }
      }))
    )
  }

  getUserProfile(userId) {
    return this.http.get<any>(this.url + '/profile' + `/${userId}`)
    .pipe(map(data => {
      return {
        userData: data.data.map(user => {
          return {
            userId: user.userId,
            firstName: user.firstName,
            lastName: user.lastName,
            title: user.title,
            email: user.email,
            password: user.email,
            dob: user.dob,
            profileImage: user.profileImage,
            twitterId: user.twitterId,
            facebookId: user.facebookId,
            isActive: user.isActive,
            phNo: user.phNo,
            address: user.address,
            city: user.city,
            country: user.country,
            state: user.state,
            company: user.company,
            postcode: user.postcode,
            mode : user.mode,
          }
        })
      }
    }) )
  }

  

  // updateUserDetails(userid){
  //   return this.http.patch<any>(this.url + '/update' +`${userid}`)
  // }

  getLoginDetails(email){
    return this.http.get<any>(this.url +'/login', {params:email})
  }

  getUserMode(email){
    return this.http.get<any>(this.url +'/login/v2', {params:{userEmail:email}})
  }

  setItem(token : string) {
    localStorage.setItem('token', token);
  }

  createUserRating(ratingDetails){
    
    return this.http.post<any>(this.ratingUrl,ratingDetails)
  }

  modifyUserRating(userRatingid,obj){

    return this.http.patch<any>(this.ratingUrl + '/update' +`/${userRatingid}`,obj);
  }

  logout(){
    this.userDetails = undefined;
    this.userDetailsUpdated.next(this.userDetails);
  }

  updateUserProfile(userId,obj){
    return this.http.patch<any>(this.url + '/update/v2' +`/${userId}`,obj);
  }
}
