import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CommonServiceService } from '../services/common-service.service';
import { MyCartService } from '../services/my-cart.service';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { SearchService } from '../services/search.service';
import Swal from 'sweetalert2';
import { WishlistService } from '../services/wishlist.service';
import { OnlinePaymentService } from '../services/online-payment.service';
import { SearchFilterService } from '../services/search-filter.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  public cartBadge;
  public firstLetter;
  public isShowDiv = false;
  public userName;
  public values: string;
  public keyword;
  public userDetails;
  public cartDetails;
  public wishlistUser;
  public transactionDetails = [];
  public categoryDetails = [];


  constructor(public authservice: AuthService, public service: CommonServiceService, public userservice: UserService,
              public cartService: MyCartService, public wishlist: WishlistService, public searchService: SearchFilterService,
              public searchKey: SearchService, public transaction: OnlinePaymentService, public router: Router) {

    this.values = this.service.searchKeyword;

    this.cartService.getCartCountListener().subscribe((cartCount) => {
      this.cartBadge = cartCount;
    });

    if (this.userservice.userDetails !== undefined) {
      this.userName = this.userservice.userDetails.data;
      this.firstLetter = this.userName.firstName.slice(0, 1) + this.userName.lastName.slice(0, 1);
    }

    if (this.userName !== undefined) {
      this.cartService.getCartdetails(this.userName.userId).subscribe((data) => {
        this.cartService.updateCartCount(data.cartData.length);
      });
    }

    this.transaction.getTransactionDetails(this.userservice.userDetails.data.userId).subscribe(data => {
      this.transactionDetails = data.transactionDetails;
    });

    this.searchService.getCategoryDetails(this.userservice.userDetails.data.userId).subscribe(category => {
      category.CategoryData.forEach(data => {
        this.categoryDetails.push(data);
      });
    });

  }

  ngOnInit(): void {
    this.userDetails = this.userservice.userDetails.data;
  }

  onKeyup(event: any) { // without type info
    this.values = event;
    this.service.searchKeyword = this.values;
  }

  clickInput() {
    this.service.searchKeyword = this.values;
    this.router.navigate(['/search']);
    this.getSearchCourses(this.service.searchKeyword);
  }

  getSearchCourses(value) {
    this.keyword = value;
    this.searchKey.searchDetails(value).subscribe(data => {

    });
  }

  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  over() {
    this.isShowDiv = true;
  }

  out() {
    this.isShowDiv = false;
  }

  overDisplay() {
    this.isShowDiv = false;
  }

  clickCart() {
    this.userDetails = this.userservice.userDetails.data;
    this.cartService.getCartdetails(this.userDetails.userId).subscribe(data => {
      this.cartDetails = data;
      if (this.cartDetails.cartData.length === 0) {
        Swal.fire({
          icon: 'info',
          text: 'No items in Cart !',
          showConfirmButton: true,
          allowOutsideClick: false,
          heightAuto: false,
        });
      } else {
        this.router.navigate(['layout/my-cart']);
      }
    });
  }

  clickWishList(){
    this.userDetails = this.userservice.userDetails.data;
    this.wishlist.getWishlistCourse(this.userDetails.userId).subscribe(data => {
      this.wishlistUser = data;
      if (this.wishlistUser.wishlistData.length === 0){
        Swal.fire({
          icon : 'info',
          text: 'No items in Wishlist !',
          showConfirmButton: true,
          allowOutsideClick: false,
          heightAuto: false,
        });
      }else{
        this.router.navigate(['layout/wishlist']);
      }
    });
  }

  clickTransaction(){
    if (this.transactionDetails.length === 0){
      Swal.fire({
        icon : 'info',
        text: 'No transactions found !',
        showConfirmButton: true,
        allowOutsideClick: false,
        heightAuto: false,
      });
    }else{
      this.router.navigate(['/layout/transactions']);
    }
  }

  async logout(){
    this.userservice.userDetails = undefined;
    const service = await this.authservice.logout();
    this.userservice.logout();
  }

  clickCourse(selectedCourse){
    if (selectedCourse.userCourseId && selectedCourse.courseType !== 'VR') {
      this.router.navigate(
        ['/course-details', { courseId: selectedCourse.courseId, userCourseId: selectedCourse.userCourseId  }],
        this.service.sharedData = selectedCourse
      );
    } else {
      this.router.navigate(
        ['/course-details', { courseId: selectedCourse.courseId }],
        this.service.sharedData = selectedCourse
      );
    }
  }

  exploreAllCourses(){
    this.service.searchKeyword = '';
    this.router.navigate(['/search']);
  }

}
