<div class="container-fluid mt-5">
    <button mat-icon-button mat-dialog-close class="ml-auto">
        <mat-icon>close</mat-icon>
    </button>
    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="text">
            <p>“Invention is the mother of necessity”, we’re reminded of this saying as we tell you our story. Like the proverb, this venture too was born out of sheer necessity. The aviation industry had bestowed upon us 2 decades of rich experience and expertise and it was the right time to return the love. How to go about it remained a puzzle though.</p> 
            <p>
                Course Assessment was conceived as our way of giving back to this industry and society the wealth of knowledge we've amassed in kind. Course Assessment’s success lies in sharing this learning to help those who are contemplating taking the next step in their careers and also the wide-eyed freshers embarking on their first professional journey.

            </p>
            <p>
                Now, the intelligence that comes after several years of recruitment campaigns and training programs has led us to unlearn & relearn the art of delivering crisp, bite-sized information to fresh millennial minds. Studies indicate that a passively acquired curriculum alone cannot equip a new entrant to be job-ready. They are bound to take at least 6 months to learn the nitty-gritty of their roles & responsibilities and to start performing well.
            </p>

            <p>
                This is where Course Assessment intervenes. We intend to bridge this gap through our web-based learning modules that do not confine the aspirants to a classroom and offer easy accessibility on the go.

            </p>

            <p>
                Our offline, classroom programs drew exemplary results, but we wanted to do more for the students and went looking for greener pastures. We are now proud to peg ourselves as ‘all caught up with technology’ owing to our recent engagement with Virtual Reality and Immersive Technology. We’ve exhaustively penned down scenarios that transpire in the airport and extrapolated those into the Virtual realm in the form of simulations. Our patrons can now experience the situations in realistic simulations rather than trying to imagine them. Visual aids generally enhance memory & engagement, but with Immersive technology, we can further amplify the retention-recall rates and take learning to the next level.
            </p>

            <p>
                Now and in the future, Course Assessment will act as the jet fuel that launches the careers of today’s Aviation aspirants by whatever means necessary. Delivering quality content is more of an obsession than an obligation.
            </p>

        </div>
    </div>
</div>