import { Overlay } from '@angular/cdk/overlay';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalWindowComponent } from '../modal-window/modal-window.component';
import { CourseVideosComponent } from 'src/app/course/course-videos/course-videos.component';

@Component({
  selector: 'app-rating-preview',
  templateUrl: './rating-preview.component.html',
  styleUrls: ['./rating-preview.component.scss']
})
export class RatingPreviewComponent implements OnInit {

  public sharedDetails : any;
  
  constructor(public dialogRef: MatDialogRef<CourseVideosComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog, public overlay: Overlay) {
    this.sharedDetails = data;
   }

  
  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(){
    this.dialogRef.close({data: 'save'});
  }

  


  ngOnInit(): void {
  }

}
