<ng-container [ngSwitch]="parseData.componentType">
    <ng-container *ngSwitchCase="'rating-preview'">
        <ng-container *ngTemplateOutlet="rating_preview;context:{data:parseData}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'feedback-rating'">
        <ng-container *ngTemplateOutlet="feedback_rating;context:{data:parseData}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'popup'">
        <ng-container *ngTemplateOutlet="popup;context:{data:parseData}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'vr-screen'">
        <ng-container *ngTemplateOutlet="vr_screen;context:{data:parseData}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'sample-certificate'">
        <ng-container *ngTemplateOutlet="sample_certificate;context:{data:parseData}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'about-us'">
        <ng-container *ngTemplateOutlet="about_us;context:{data:parseData}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'revisit-video'">
        <ng-container *ngTemplateOutlet="revisit_video;context:{data:parseData}"></ng-container>
    </ng-container>
</ng-container>



<ng-template #rating_preview let-share="data">
   <app-rating-preview></app-rating-preview>
</ng-template>
<ng-template #feedback_rating let-share="data">
   <app-feedback-rating></app-feedback-rating>
</ng-template>
<ng-template #popup let-share="data">
    <app-video-screen-popup></app-video-screen-popup>
</ng-template>
<ng-template #vr_screen let-share="data">
    <app-vr-screen></app-vr-screen>
</ng-template>
<ng-template #sample_certificate let-share="data">
    <app-sample-certificate></app-sample-certificate>
 </ng-template>
 <ng-template #about_us let-share="data">
    <app-about-us></app-about-us>
 </ng-template>
 <ng-template #revisit_video let-share="data">
    <app-revisit-video></app-revisit-video>
 </ng-template>



