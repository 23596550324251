<section class="dashboard-search-section " *ngIf="CourseDetails.length > 0">
    <div class="example-sidenav-content container-fluid container-lg pt-4"
        *ngFor="let list of CourseDetails">
        <h3 class="text-light">COURSE</h3>
        <div class="d-flex">
            <h1 class="text-light"><b>{{list.courseName}}</b></h1>
            
            <div class="p-2 ml-auto mr-2">
               

                <p class="text-light p-0 m-0 text-center">{{percentage}}% Complete</p>
                <mat-progress-bar class="top-progress"
                    [ngClass]="{'accent': percentage < 25,'blue': percentage >= 25 && percentage <= 99, 'green':percentage === 100}"
                    mode="determinate" [value]="percentage"></mat-progress-bar>
            </div>
            <!-- <div class="continueBtn" *ngIf="percentage === 100"><button mat-button (click)="clickCard(list)">RETAKE COURSE</button>
            </div> -->
            <div class="continueBtn"><button mat-button *ngIf="percentage === 0" (click)="startOver(list)">Start Course</button></div>
            <div class="continueBtn"><button mat-button *ngIf="percentage > 0 && percentage <=99.9" (click)="startOver(list)">Start Over</button></div>
            <div class="continueBtn ml-2" *ngIf="percentage > 0 && percentage <=99.9 "><button mat-button (click)="resume(list)">Resume</button></div>
            <div class="continueBtn ml-2" *ngIf="percentage >= 100"><button mat-button (click)="startOver(list)">Retake Course</button></div>

        </div>
        <mat-divider color="primary"></mat-divider>
        <div class="enrolledCourses  mt-3 mb-5">
            <mat-card class="mt-2 " *ngFor="let data of list.courseModules">
                <h4 style="font-size: 14px;color: #FFFFFFDE;text-transform: uppercase;">
                    {{data.moduleName}}</h4>
                <div style="display: flex;padding: 10px 0px 10px 0px;"
                    *ngFor="let submodule of data.CourseLessons">

                    <div class="spinner-container">
                        <div class="spinner-background"></div>
                        <mat-progress-spinner
                            [ngClass]="{'custom-spinner-completed ': submodule.completedPercentage >= 75, 'custom-spinner-inprogress': submodule.completedPercentage >= 25 && submodule.completedPercentage < 75, 'custom-spinner-notStarted': submodule.completedPercentage===0, 'custom-spinner-below25' : submodule.completedPercentage < 25 }"
                            diameter="20" mode="determinate"
                            [value]="submodule.completedPercentage===0 ? 100 : submodule.completedPercentage ">
                        </mat-progress-spinner>
                    </div>



                    <p class="ml-3"
                        [ngClass]="{'completed-lessonText': submodule.completedPercentage === 100, 'inProgress-lessonText': submodule.completedPercentage >= 25 && submodule.completedPercentage <= 99,'notStarted-lessonText': submodule.completedPercentage === 0, 'below25-lessonText' : submodule.completedPercentage < 25 && submodule.completedPercentage != 0 }">
                        {{submodule.lessonName}}</p>


                    <p class="ml-auto"
                        [ngClass]="{'completed-lessonText': submodule.completedPercentage === 100, 'inProgress-lessonText': submodule.completedPercentage >= 25 && submodule.completedPercentage <= 99,'notStarted-lessonText': submodule.completedPercentage === 0, 'below25-lessonText' : submodule.completedPercentage < 25 && submodule.completedPercentage != 0 }">
                        {{submodule.courseType === 'pdf' ? 'PDF' :submodule.durationMinSec}}</p>
                </div>

                <mat-divider *ngIf="data.moduleStatus === 'IN PROGRESS'"></mat-divider>
                <div class="module-progress pt-3 d-flex"
                    *ngIf='data.moduleStatus === "IN PROGRESS" && data.percentage === 0'>

                    <!-- <button mat-button class="resumeBtn text-white ml-3"
                        (click)="clickCard(list)">START</button> -->

                </div>
                <div class="module-progress pt-3 d-flex"
                    *ngIf='data.moduleStatus === "IN PROGRESS" && data.percentage != 0'>

                    <!-- <button mat-button class="resumeBtn text-white ml-3"
                        (click)="clickCard(list)">RESUME</button> -->
                </div>
                <!-- <div class="module-progress pt-3 d-flex" *ngIf='data.moduleStatus === "IN PROGRESS"'>
                    <button mat-button class="yetToStartBtn text-light ml-3">IN PROGRESS</button>
                </div>
                <div class="module-progress pt-3 d-flex" *ngIf='data.moduleStatus === "YET TO START"'>
                    <button mat-button class="yetToStartBtn text-light ml-3">YET TO START</button>
                </div> -->

            </mat-card>
        </div>
        <!-- <div class="medal mt-5">
            <img src="../../../assets/images/medal (2).svg" />
            <h2 class="mt-2">Masters in {{list.courseName}}</h2>
        </div> -->
    </div>
</section>

<div *ngIf="CourseDetails.length === 0" class="my-5">
    <div class="example-sidenav-content container-fluid container-lg ">
        <app-skeleton-loader animated style="width: 100px;height: 20px;background: #4C4B56 0% 0% no-repeat padding-box;"></app-skeleton-loader>
        <div class="d-flex">
            <app-skeleton-loader animated style="height: 20px;background: #4C4B56 0% 0% no-repeat padding-box;"></app-skeleton-loader>

        </div>

        <div class="enrolledCourses  mt-3">
            <mat-card class="mt-2 bg-backround-dark">
                <app-skeleton-loader animated class="mb-2" style="width:100px; height: 20px;"></app-skeleton-loader>
                <div class="row mb-3">
                    <div class="col-8">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                    <div class="offset-2 col-2">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-8">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                    <div class="offset-2 col-2">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-8">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                    <div class="offset-2 col-2">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                </div>
                <app-skeleton-loader animated class="mb-2"style="width:100px; height: 40px;"></app-skeleton-loader>
            </mat-card>
        </div>
       
        <div class="enrolledCourses  mt-3">
            <mat-card class="mt-2 bg-backround-dark">
                <app-skeleton-loader animated class="mb-2" style="width:100px; height: 20px;"></app-skeleton-loader>
                <div class="row mb-3">
                    <div class="col-8">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                    <div class="offset-2 col-2">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-8">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                    <div class="offset-2 col-2">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-8">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                    <div class="offset-2 col-2">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                </div>
                <app-skeleton-loader animated class="mb-2"style="width:100px; height: 40px;"></app-skeleton-loader>
            </mat-card>
        </div>
       
        <div class="enrolledCourses  mt-3">
            <mat-card class="mt-2 bg-backround-dark">
                <app-skeleton-loader animated class="mb-2" style="width:100px; height: 20px;"></app-skeleton-loader>
                <div class="row mb-3">
                    <div class="col-8">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                    <div class="offset-2 col-2">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-8">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                    <div class="offset-2 col-2">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-8">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                    <div class="offset-2 col-2">
                        <app-skeleton-loader animated style="height: 20px;"></app-skeleton-loader>
                    </div>
                </div>
                <app-skeleton-loader animated class="mb-2"style="width:100px; height: 40px;"></app-skeleton-loader>
            </mat-card>
        </div>
    </div>
</div>