import { Component, OnInit } from '@angular/core';
import { OnlinePaymentService } from 'src/app/services/online-payment.service';
import { UserService } from 'src/app/services/user.service';
import { element } from 'protractor';
import { Router } from '@angular/router';
import { MyCartService } from 'src/app/services/my-cart.service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import Swal from 'sweetalert2';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  public userDetails;
  public transactionData: any = [];
  public transactionDetail: any = [];
  public categoryDetails = [];
  public coursePurchasedLength;
  public selected;
  public userName;
  public cart;
  public firstLetter;
  public cartBadge;
  public databaseUser;
  public wishlistUser;
  public isShowDiv = false;
  public disabled = false;
  public values;

  constructor(public transaction: OnlinePaymentService, public userservice: UserService, public cartService: MyCartService,
              public searchService: SearchFilterService, public authservice: AuthService, public service: CommonServiceService,
              public wishlist: WishlistService, public router: Router) {

    this.userDetails = this.userservice.userDetails.data;

    this.transaction.getTransactionDetails(this.userDetails.userId).subscribe(data => {
      data.transactionDetails.forEach((element: any) => {
        element.coursePurchaseds = element.coursePurchaseds;
        this.transactionData.push(element);

        // console.log(this.transactionData)
        this.transactionDetail.push(element);
        this.coursePurchasedLength = this.transactionData.length;
        this.transaction.transactionLength = this.coursePurchasedLength;
        // this.coursePurchasedLength = this.transactionData.coursePurchaseds.length
        // console.log(this.transactionData)
      });
    });

   }

  ngOnInit(): void {
  }

  onChange(value) {
    if (value === 'Past 1 months') {
      const startDate = new Date(new Date().setDate(-30));
      const endDate = new Date();
      this.transactionData = this.transactionDetail.filter(a => new Date(a.createdAt) > startDate && new Date(a.createdAt) < endDate);
    } else if (value === 'Past 3 months') {
      const startDate = new Date(new Date().setDate(-90));
      const endDate = new Date();
      this.transactionData = this.transactionDetail.filter(a => new Date(a.createdAt)  > startDate && new Date (a.createdAt) < endDate);
    } else if (value === 'Past 6 months') {
      const startDate = new Date(new Date().setDate(-180));
      const endDate = new Date();
      this.transactionData = this.transactionDetail.filter(a => new Date (a.createdAt) > startDate && new Date (a.createdAt) < endDate);
    }

  }


  clickInvoice(data){
    const invoiceData = {
      userTransactionId : data.userTransactionId,
      transactionDate : data.createdAt,
      totalCost : data.totalCost,
      billingAddress : data.billingAddress
    };
    this.router.navigate(['/layout/invoice'], {queryParams: {transactionId: data.userTransactionId} });
    this.transaction.transactionDetails = invoiceData;
  }

}
