import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CourseVideosComponent } from 'src/app/course/course-videos/course-videos.component';

@Component({
  selector: 'app-revisit-video',
  templateUrl: './revisit-video.component.html',
  styleUrls: ['./revisit-video.component.css']
})
export class RevisitVideoComponent implements OnInit {

  public getData;
  public videoLink: string = '';
  @ViewChild('pdf', { static: false }) pdfFile: ElementRef;

  constructor(public dialogRef: MatDialogRef<CourseVideosComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {
    this.getData = data.data;
    if (this.getData.standardVideo && this.getData.standardVideo !== '') {
      this.videoLink = this.getData.standardVideo;
    } else if (this.getData.hdVideo && this.getData.hdVideo !== '') {
      this.videoLink = this.getData.hdVideo;
    } else if (this.getData.fullHdVideo && this.getData.fullHdVideo !== '') {
      this.videoLink = this.getData.fullHdVideo;
    }
   }

   ngAfterViewInit(){
     if (this.pdfFile) {
      this.pdfFile.nativeElement.src = this.getData.pdfFile + '#toolbar=0';
     }
   }

  ngOnInit(): void {
   
  }


}
