import { Component, OnInit, ViewChild, ViewChildren, HostBinding } from '@angular/core';
import { CommonServiceService } from 'src/app/services/common-service.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { MyCartService } from 'src/app/services/my-cart.service';
import { SearchFilterService } from 'src/app/services/search-filter.service';
import Swal from 'sweetalert2';
import { WishlistService } from 'src/app/services/wishlist.service';
import { OnlinePaymentService } from 'src/app/services/online-payment.service';
import { UserProgressService } from 'src/app/services/user-progress.service';
import { SearchService } from 'src/app/services/search.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  maxStars = 5;
  userDetails;
  databaseUser = {      firstName: '',      lastName: '',      email: '',      password: '',      courses: []  };
  // coursesList: any ;
  selectedCouserByUser;
  selectedScreen;
  public courseName;
  public courseFilter;
  public courses: any = [];
  public search = '';
  public noCourse = false;
  public notEnrolled = false;
  public courseLength ;
  public coursePurchased;
  public cart = false;
  public cartDetails;
  public userRating;
  public disabled = false;
  public showFiller = false;
  public isCollapsed = false;
  public hide = true;
  public percentage;
  public isCourseLoaded = false;

  @HostBinding('class.flex-fill') autoHeight = true;

  // @ViewChild('sidenav') sidenav: MdSidenav;
  constructor(public authservice: AuthService, public service: CommonServiceService, public userservice: UserService,
              public cartService: MyCartService, public userProgress: UserProgressService,
              public searchKey: SearchService, public searchService: SearchFilterService,
              public wishlist: WishlistService, public transaction: OnlinePaymentService, public router: Router) {

  }

  ngOnInit(): void {

    this.userDetails = this.userservice.userDetails.data;
    this.isCourseLoaded = false;
    this.userservice.getUserDetails(this.userDetails.userId).subscribe(data => {
      // console.log(data)
      this.noCourse = true;
      this.notEnrolled = true;
      this.databaseUser = data.userData[0];
      this.courseFilter = data.userData[0];
      // console.log(this.databaseUser);
      this.courses = this.databaseUser.courses;
      this.courses.map(x => {
        this.userRating = x.userRatings;
      });
      this.service.coursePurchased = this.databaseUser.courses;
      this.isCourseLoaded = true;
    });
  }

  clickCard(selectedCourse){
    this.selectedCouserByUser = selectedCourse;
    this.selectedScreen = false;
    const obj = {
      userCourseId : selectedCourse.userCourseId,
      courseId : selectedCourse.courseId,
      userId : selectedCourse.userId,
    };
    this.userProgress.updateProgress(obj).subscribe(x => {
      if (x.success){
        this.router.navigate(['/layout/dashboard-course-details', {usercourse: selectedCourse.userCourseId}],
          this.service.sharedData = selectedCourse
        );
      }
    });
  }

  onKey(value: string){
    this.courses.filter = value.trim().toLowerCase();
  }

  viewCourses(){
    this.service.searchKeyword = '';
    this.router.navigate(['/search']);
  }

  courseExpired(course) {
      if (course.courseStatus === 'COMPLETED') {
        Swal.fire({
          icon: 'warning',
          html: '<div>We regret to inform that the course<br>' + ' ' + '<b>' + '"' + course.courseName + '"' +'</b>' + ' ' + '<br>has crossed its validity and unavailable for further learning.</div>',
          footer : '<div>To continue learning please repurchase the course</div>',
          confirmButtonText : 'Buy now',
          showCloseButton: true,
          heightAuto: false,
          allowOutsideClick: false,
        }).then((res)=>{
          if (res.value){
            this.service.sharedData = course;
            this.router.navigate(['/course-details'], {queryParams: {courseId: course.courseId} });
          }
        })
      }else{
        Swal.fire({
          icon: 'warning',
          html: '<div>We regret to inform that the course<br>' + ' ' + '<b>' + '"' + course.courseName + '"' +'</b>' + ' ' + '<br>has crossed its validity.</div>',
          footer : '<div>To continue learning please repurchase the course</div>',
          confirmButtonText : 'Buy now',
          showCloseButton: true,
          heightAuto: false,
          allowOutsideClick: false,
        }).then((res) => {
          if (res.value){
            this.service.sharedData = course;
            this.router.navigate(['/course-details'], {queryParams: {courseId: course.courseId} });
          }
        });
      }
  }
}
