import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import Swal from 'sweetalert2';
import * as firebase from 'firebase';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  public emailUserForm;

  constructor(public authservice: AuthService,  private formBuilder: FormBuilder, public router: Router, public userService: UserService) {
    this.emailUserForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],    
    });
  }

  submitForm(){
    const emailUsers = {
      userEmail: this.emailUserForm.get('email').value,
    }
    if(this.emailUserForm.valid){
      this.userService.getUserMode(emailUsers.userEmail).subscribe(result=>{
        if(result.data.mode === "Email"){
          this.authservice.sendPasswordResetEmail(emailUsers.userEmail)
          .then(result=>{
            Swal.fire({
              icon: 'success',
              text: 'A password reset link has been sent to your email address. Kindly check your inbox.',
              showConfirmButton: true,
              heightAuto: false,
            }).then(result=>{
              if(result.isConfirmed){
                this.router.navigate(['email-login']);
              }
            })
          }).catch(error=>{
            Swal.fire({
              icon: 'error',
              text: error.message,
              showConfirmButton: true,
              heightAuto: false,
            })
          })
        }else{
          Swal.fire({
            icon: 'error',
            text: "You Created your account through Google/Facebook so you can't change your password",
            showConfirmButton: true,
            heightAuto: false,
          })
        }
      },err => {
        Swal.fire({
          icon: 'error',
          text: err.error.message,
          showConfirmButton: true,
          heightAuto: false,
        })
      })
    }else{
      Swal.fire({
        icon: 'error',
        text: 'The email you have entered is not a valid email',
        showConfirmButton: true,
        heightAuto: false,
      })
    }
   
  }

  ngOnInit(): void {
  }

}
